<template>
  <div
    v-if="startPhaseState.showBar && tutorialState.showBarStart"
    class="nomobile-start-bar"
  >
    <start-efficiency-bar
      :forced-size="100"
      :glow="true"
    />
  </div>
  <div
    v-if="gamePhaseState.showBar && !actionButtonState.disabled && tutorialState.showBarSpeed"
    class="nomobile-start-bar"
  >
    <speed-bar />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import StartEfficiencyBar from '@/components/Inputs/StartEfficiencyBar.vue'
import SpeedBar from '@/components/Inputs/SpeedBar.vue'
import {
  actionButtonState,
  gamePhaseState,
  startPhaseState,
  tutorialState
} from '@/stores'

export default defineComponent({
  name: 'TutorialBars',
  components: {
    StartEfficiencyBar,
    SpeedBar
  },
  data() {

    return {
      actionButtonState: actionButtonState(),
      gamePhaseState: gamePhaseState(),
      startPhaseState: startPhaseState(),
      tutorialState: tutorialState(),
    }

  },
})
</script>

<style scoped lang="less">
.tutorial {
    .nomobile-start-bar {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 375px;
        height: 375px;
    }
}
</style>
