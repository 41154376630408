<template>
  <biathlon-split-times
    v-if="splitTimeState.splitTimeData.length > 0"
    :key="splitTimeState.rerenderKey"
    :row-data="splitTimeState.splitTimeData"
    :show-player-time="splitTimeState.showPlayerTime"
    style="top: 1%; margin: 0.25em 0px;"
  />
</template>

<script lang="ts">
import { BiathlonSplitTimes } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { modes } from '@powerplay/core-minigames'
import { splitTimeState } from '@/stores'

export default defineComponent({
  name: 'SplitTimesBiathlon',
  components: {
    BiathlonSplitTimes
  },
  data() {

    return {
      rowData: [],
      splitTimeState: splitTimeState(),
    }

  },
  computed: {
    showSplitTimes() {

      return !modes.isTutorial()

    }
  },
})
</script>

<style scoped lang="less">
</style>
