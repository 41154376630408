<template>
  <div class="hud">
    <div class="info">
      <div>Max speed: {{ mainState.maxSpeed.toFixed(2) }}</div>
      <div>VelocityX: {{ mainState.velocityX.toFixed(4) }}</div>
      <div>VelocityY: {{ mainState.velocityY.toFixed(4) }}</div>
      <div>VelocityZ: {{ mainState.velocityZ.toFixed(4) }}</div>
      <div>Gradient: {{ mainState.gradient.toFixed(4) }}</div>
      <div>Last sprint bonus: {{ mainState.sprintBonus.toFixed(4) }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mainState } from '@/stores'

export default defineComponent({
  name: 'HUD',
  data() {

    return {
      mainState: mainState(),
    }

  }
})
</script>

<style lang="less" scoped>
.hud {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .info {
     display: flex;
     flex-direction: column;
  background: black;
  color: white;
  width:  16.666667%;
  }
}
</style>
