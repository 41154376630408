import { defineStore } from 'pinia'

export interface SprintState {
  isSprinting: boolean,
  tutorialFreeze: boolean
}

const initialState = (): SprintState => ({
  isSprinting: false,
  tutorialFreeze: false
})

export const sprintState = defineStore('sprintState', {
  state: initialState,
})
