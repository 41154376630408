import {
  PlayerAnimationsNames,
  type InternalCrossfadeData
} from '@/app/types'
import { player } from './Player'

/**
 * Sprava internych crossfadeov
 */
export class InternalCrossfadesManager {

  /** Ci je dostupny interny crossfade */
  private active = false

  /** Info ohladom internych crossfadeov */
  private info: {[key in PlayerAnimationsNames]?: InternalCrossfadeData } = {
    [PlayerAnimationsNames.v1Skating]: {
      weightStart: 0,
      weightEnd: 0,
      frames: 0,
      actualFrame: 0
    },
    [PlayerAnimationsNames.downhill]: {
      weightStart: 0,
      weightEnd: 0,
      frames: 0,
      actualFrame: 0
    },
    [PlayerAnimationsNames.downhillLeft]: {
      weightStart: 0,
      weightEnd: 0,
      frames: 0,
      actualFrame: 0
    },
    [PlayerAnimationsNames.downhillRight]: {
      weightStart: 0,
      weightEnd: 0,
      frames: 0,
      actualFrame: 0
    },
    [PlayerAnimationsNames.v2Skating]: {
      weightStart: 0,
      weightEnd: 0,
      frames: 0,
      actualFrame: 0
    },
    [PlayerAnimationsNames.v3Skating]: {
      weightStart: 0,
      weightEnd: 0,
      frames: 0,
      actualFrame: 0
    },
    [PlayerAnimationsNames.sprint]: {
      weightStart: 0,
      weightEnd: 0,
      frames: 0,
      actualFrame: 0
    },
    [PlayerAnimationsNames.shootingComeInPreStop]: {
      weightStart: 0,
      weightEnd: 0,
      frames: 0,
      actualFrame: 0
    },
    [PlayerAnimationsNames.stop2]: {
      weightStart: 0,
      weightEnd: 0,
      frames: 0,
      actualFrame: 0
    },
  }

  /**
   * Nastavenie opacneho procesu crossfadeu pre vsetky aktivne interne crossfadey (tj vahu chceme dat na hodnotu 0)
   * @param frames - Pocet frameov na tento proces
   * @param exceptAnimation - Ktora animacia je vynimka a nechceme, aby sa pridala do tohto procesu
   */
  private reverseAllActive(frames: number, exceptAnimation: PlayerAnimationsNames): void {

    for (const [key, value] of Object.entries(this.info)) {

      // iba aktivne a mimo vynimky
      const weight = player.animationsManager.getAnimationAction(key).weight
      if (key !== exceptAnimation && value.frames > 0 && weight > 0) {

        value.actualFrame = 0
        value.frames = frames
        value.weightStart = player.animationsManager.getAnimationAction(key).weight
        value.weightEnd = 0

      }

    }

  }

  /**
   * Nastavenie casu novej animacie pomocou casu starej animacie, pomerovo
   * @param animationNew - nova animacia
   * @param animationOld - stara animacia
   */
  private setNewAnimationTimeFromOldAnimation(
    animationNew: PlayerAnimationsNames,
    animationOld: PlayerAnimationsNames
  ): void {

    const timeOld = player.animationsManager.getAnimationAction(animationOld).time
    const durationOld = player.animationsManager.getDuration(animationOld)
    const durationNew = player.animationsManager.getDuration(animationNew)
    const percent = durationOld === 0 ? 0 : timeOld / durationOld
    const timeNew = durationNew * percent

    player.animationsManager.getAnimationAction(animationNew).time = timeNew

  }

  /**
   * Nastavenie interneho crossfadeu, kde chceme, aby sa weight dostala casom na hodnotu 1, ostatne robi reverse metoda
   * @param animation - Animacia
   * @param animationRunning - Aktualne beziaca animacia
   * @param frames - Pocet frameov na tento proces
   * @param changeTime - Ci sa ma nastavovat cas podla starej animacie, v podstate keepTime parameter crossfadeTo
   * @returns Nova beziaca animacia
   */
  public setCrossfade(
    animation: PlayerAnimationsNames,
    animationRunning: PlayerAnimationsNames,
    frames: number,
    changeTime = true
  ): PlayerAnimationsNames | undefined {

    // nastavime novy interny crossfade, ale iba ked tato animacia nie je posledna
    if (!this.active || animationRunning === animation) return undefined

    // interny crossfade animacie este bezi, takze bude treba prejst vsetky animacie a nastavit ich opacne
    this.reverseAllActive(frames, animation)

    this.info[animation] = {
      actualFrame: 0,
      frames: frames,
      weightStart: player.animationsManager.getAnimationAction(animation).weight,
      weightEnd: 1
    }

    // nastavme este cas animacie na rovnaky ako mala predosla animacia pomerovo - iba mimo tych na zmenu drah
    if (changeTime) this.setNewAnimationTimeFromOldAnimation(animation, animationRunning)

    return animation

  }

  /**
   * Sprava internych crossfadeov
   */
  public update(): void {

    if (!this.active) return

    for (const [key, value] of Object.entries(this.info)) {

      // iba aktivne
      if (value.actualFrame < value.frames && value.frames > 0) {

        value.actualFrame += 1
        const percent = value.actualFrame / value.frames

        // a teraz upravime vahu
        const newWeightRaw = value.weightStart + ((value.weightEnd - value.weightStart) * percent)
        const newWeight = Math.max(Math.min(newWeightRaw, 1), 0) // aplikujeme min a max
        player.animationsManager.setWeight(key, newWeight)

      }

    }

  }

  /**
   * Zapnutie internych crossfadeov
   * @param startAnimation - Startovacia animacia, aby sa vedelo, co ma vahu 1 na zaciatku
   */
  public enable(startAnimation?: PlayerAnimationsNames): void {

    this.active = true

    // ak potrebujeme dat, aby prva animacia bola uz beziaca, tak davame takto
    if (startAnimation) {

      this.info[startAnimation] = {
        actualFrame: 1,
        frames: 1,
        weightStart: 1,
        weightEnd: 1
      }

      player.animationsManager.setWeight(startAnimation, 1)

    }
    console.log('ENABLE IC')

  }

  /**
   * Vypnutie internych crossfadeov
   * @param animationRunning - Aktualne beziaca animacia
   */
  public disable(animationRunning: PlayerAnimationsNames): void {

    if (!this.active) return

    console.log('DISABLE IC')

    this.active = false

    // musime este poriesit vsetky vahy, aby sa zresetovali na spravne hodnoty
    for (const key of Object.keys(this.info)) {

      const weight = key === animationRunning ? 1 : 0
      player.animationsManager.setWeight(key, weight)

    }

  }

  /**
   * Zresetovanie
   */
  public reset(): void {

    this.active = false

    this.info = {
      [PlayerAnimationsNames.v1Skating]: {
        weightStart: 0,
        weightEnd: 0,
        frames: 0,
        actualFrame: 0
      },
      [PlayerAnimationsNames.downhill]: {
        weightStart: 0,
        weightEnd: 0,
        frames: 0,
        actualFrame: 0
      },
      [PlayerAnimationsNames.downhillLeft]: {
        weightStart: 0,
        weightEnd: 0,
        frames: 0,
        actualFrame: 0
      },
      [PlayerAnimationsNames.downhillRight]: {
        weightStart: 0,
        weightEnd: 0,
        frames: 0,
        actualFrame: 0
      },
      [PlayerAnimationsNames.v2Skating]: {
        weightStart: 0,
        weightEnd: 0,
        frames: 0,
        actualFrame: 0
      },
      [PlayerAnimationsNames.v3Skating]: {
        weightStart: 0,
        weightEnd: 0,
        frames: 0,
        actualFrame: 0
      },
      [PlayerAnimationsNames.sprint]: {
        weightStart: 0,
        weightEnd: 0,
        frames: 0,
        actualFrame: 0
      },
      [PlayerAnimationsNames.shootingComeInPreStop]: {
        weightStart: 0,
        weightEnd: 0,
        frames: 0,
        actualFrame: 0
      },
      [PlayerAnimationsNames.stop2]: {
        weightStart: 0,
        weightEnd: 0,
        frames: 0,
        actualFrame: 0
      },
    }

  }

}
