/**
 * Specialny konfig pre hracove animacie
 */
export const playerAnimationConfig = {

  /**
   * Kolko bude skating cyklov na zaciatku
   */
  skatingLoopsOnStart: 2,

  /**
   * Zakladny cas pre crossfade
   */
  defaultCrossfadeTime: 0.02,

  /**
   * Rychlost animacii pre sprint
   */
  animationSpeedForSprint: 1.5,

  /**
   * Rychlost animacii pre skating
   */
  animationSpeedForSkating: 0.8,

  /**
   * Rychlost animacii pre outro
   */
  animationSpeedForOutro: 0.8
}
