import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/*
 * pozicia poslednej branky pre config veci nizsie
 * const lastGate = batchingConfig.get('Branka0')
 * const lastGateData = lastGate ? lastGate.data : [{ offset: new THREE.Vector3() }]
 * const lastGatePosition = lastGateData[lastGateData.length - 1].offset.clone()
 */

/**
 * Konfig pre kameru pre vsetky stavy
 */
export const cameraConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(-19.2796, 6.35044, 55.614),
      endPosition: new THREE.Vector3(-20.3974, 2.84067, 33.2674),
      startRotation: new THREE.Vector3(1.43657, -0.11427, -0.095936),
      endRotation: new THREE.Vector3(1.6312, 0.174996, -0.09754),
      duration: 5,
      notSkippableFrames: 20,
      fov: 86.9
    },
    [CameraStates.intro2]: {
      startPosition: new THREE.Vector3(-24.3795, 1.94975, 27.6359),
      endPosition: new THREE.Vector3(-24.1231, 1.94975, 7.52888),
      startRotation: new THREE.Vector3(1.5698, 0.07666, -1.558),
      duration: 3,
      notSkippableFrames: 20,
      fov: 78.6
    },
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(100, 55, -10),
      startLookAt: new THREE.Vector3(-25, 1, 50),
      endPosition: new THREE.Vector3(101, 56, -11),
      duration: 5
    },
    [CameraStates.disciplineIntro]: [
      {
        startPosition: new THREE.Vector3(12.4726, 7.05058, 16.0968),
        endPosition: new THREE.Vector3(9.50188, 5.99833, 0.284158),
        startRotation: new THREE.Vector3(1.502516, 0.00603, -0.08817),
        endRotation: new THREE.Vector3(1.55286, 0.18123, -0.680554),
        duration: 3,
        fov: 86.9
      },
      {
        startPosition: new THREE.Vector3(-3.31676, 0.626287, -15.9105),
        endPosition: new THREE.Vector3(-7.15303, 1.44252, -16.0059),
        startRotation: new THREE.Vector3(1.5756, -0.0022, -0.4346),
        endRotation: new THREE.Vector3(1.4201, -0.03935, 0.253876),
        duration: 3,
        fov: 58.7
      }
    ],
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: {
      startPosition: new THREE.Vector3(-2, 2, 3),
      endPosition: new THREE.Vector3(2, 2, 3),
      duration: 10,
      offsetCamera: true
    },
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5

}
