
import {
  THREE,
  game,
  type CannonNamedBody,
  CANNON
} from '@powerplay/core-minigames'
import { gameConfig } from '../../config'
import { player } from '../player'

/**
 * Trieda pre kopec
 */
export class Hill {

  /** Mesh kopca */
  public hillMesh!: THREE.Mesh

  /** Mesh rohu trate */
  // edgeMesh!: THREE.Mesh;

  /** Fyzikalne body kopca */
  public hillBodies: CannonNamedBody[] = []

  /** Fyzikalny material pre kontakt hraca s barierou */
  private physicsBarrierMaterial = new CANNON.Material('physics_barrier')

  /** Pole fyzikalnych meshov */
  public PHYSICS_MESHES = ['Physics_Barrier_Finish', 'Physics_Barrier']

  /**
   * Vytvorenie kopca
   */
  public create(): void {

    console.log('VYTVARAM HILL....')
    this.createHillPhysics()

    console.log('HILL vytvoreny....')

    game.physics.setWorldConfig({
      gravitation: gameConfig.gravitation
    })

  }

  /**
   * Vytvorenie fyziky kopca
   */
  private createHillPhysics(): void {

    this.hillMesh = game.getMesh('Physics_Track')
    this.hillMesh.visible = false

    for (let i = 0; i <= 16; i++) {

      const physicsName = `Physics_Track_${i}`
      const physicsMesh = game.getMesh(physicsName)
      physicsMesh.visible = false
      const hillBody = game.physics.addBodyFromMesh(physicsName, physicsMesh, 0)
      hillBody.position.x = physicsMesh.position.x
      hillBody.position.y = physicsMesh.position.y
      hillBody.position.z = physicsMesh.position.z
      this.hillBodies.push(hillBody)

    }

    this.createEdgePhysics()

  }

  /**
   * Vytvorenie fyzikalnych barier
   */
  private createEdgePhysics(): void {

    const meshEdgesNames = [
      /* 'Physics_Barrier_Start', */'Physics_Barrier_Finish', 'Physics_Barrier'
    ]

    meshEdgesNames.forEach((meshName) => {

      const mesh = game.getMesh(meshName)
      const physicsName = meshName
      mesh.visible = false
      const body = game.physics.addBodyFromMesh(physicsName, mesh, 0)
      body.material = this.physicsBarrierMaterial

    })

    const barrierStart = game.getMesh('Barrier_Start')
    barrierStart.visible = false

    const barrierStartPhysics = game.getMesh('Physics_Barrier_Start')
    barrierStartPhysics.visible = false

    const contactMaterial = new CANNON.ContactMaterial(
      this.physicsBarrierMaterial,
      player.playerPhysicsMaterial,
      {
        restitution: gameConfig.restitutionHillPlayer,
        friction: gameConfig.frictionHillPlayer,
        frictionEquationRelaxation: gameConfig.frictionEquationRelaxationHillPlayer,
        frictionEquationStiffness: gameConfig.frictionEquationStiffnessHillPlayer,
        contactEquationRelaxation: gameConfig.contactEquationRelaxationHillPlayer,
        contactEquationStiffness: gameConfig.contactEquationStiffnessHillPlayer
      }
    )
    game.physics.getPhysicsWorld.addContactMaterial(contactMaterial)

  }

}

export const hill = new Hill()
