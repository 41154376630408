import { AudienceVolumeSectorTypes } from '../types'

/**
 * trieda pre spravu konstant pre audio
 */
export const audioGameConfig = {

  /** v ktorych splitoch nehrajeme split time audio */
  splitTimesNoComment: [2, 3, 4],

  /** kolko framov po vystrele prehrame audio hit/miss */
  afterShootDelayHit: 5,

  /** kolko framov po vystrele prehrame audio natahovania */
  afterShootDelayPull: 15,

  /** aku minimalnu hlasitost maju divaci */
  minAudienceSound: 0.2,

  /** ako dlho trva dostat sa na minimalny zvuk */
  minAudienceDuration: 5,

  /** aku maximalnu hlasitost maju divaci */
  maxAudienceSound: 1,

  /** ako dlho trva dostat sa na maximalny zvuk */
  maxAudienceDuration: 3,

  /** kedy zacne buchat srdce pri behu */
  hearthRateRunningTreshold: 180,

  /** kedy dychcat srdce pri behu */
  breathingRunningTreshold: 160,

  /** ako zvysujeme hlasitost divakov */
  finishAudienceIncreaseVolume: {

    /** ako casto */
    frames: 3,

    /** o kolko */
    step: 0.1

  },

  /** Hlasitost heartbeatu pri strelbe */
  heartRateShootingVolume: {

    min: 0.5,
    max: 1

  },

  /** Ake typy sektorov znamenaju postupne menenie hlasitosti */
  audienceVolumeSectorTypesGradually: [
    AudienceVolumeSectorTypes.graduallyDecrease,
    AudienceVolumeSectorTypes.graduallyIncrease
  ]

}
