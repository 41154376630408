/**
 * Mena sekcie
 */
export enum SectionNames {
  handCheck = 'handCheck',
  startSection = 'startSection',
  startSectionSecond = 'startSectionSecond',
  startSectionThird = 'startSectionThird',
  startSectionFourth = 'startSectionFourth',
  startSectionFifth = 'startSectionFifth',
  startSectionSixth = 'startSectionSixth',
  startSectionSeventh = 'startSectionSeventh',
  startSectionEight = 'startSectionEight',
  startSectionNinth = 'startSectionNinth',
  startSectionTenth = 'startSectionTenth',
  startSectionEleventh = 'startSectionEleventh',
  startSectionTwelfth = 'startSectionTwelfth',
  startSectionThirteenth = 'startSectionThirteenth',
  startSectionFourteenth = 'startSectionFourteenth'
}

type NameKeys = keyof typeof SectionNames;
type NameKeyFields = { [key in NameKeys]: string }

export interface UiState extends NameKeyFields {
  NameKeyFields: () => void
}

export enum TutorialEventType {
  startOfFirstHill = 'startOfFirstHill',
  endOfFirstSprint = 'endOfFirstSprint',
  startOfFirstDownhill = 'startOfFirstDownhill',
  endOfFirstDownhill = 'endOfFirstDownhill',
  shootingStart = 'shootingStart',
  shootingEnd = 'shootingEnd',
  finishEvent = 'finishEvent',
  awaitingEvent = 'awaitingEvent'
}

export enum TutorialObjectiveIds {
  start = 'tutorialTask2-1',
  sprint = 'tutorialTask2-2',
  downhill = 'tutorialTask2-3',
  shooting = 'tutorialTask2-4'
}
