<template>
  <section class="positioner">
    <section
      :class="[!gameSettingsState.isLeft ? 'row' : 'row-reverse']"
      class="flex"
    >
      <div
        :class="[gameSettingsState.isLeft ? 'bottom-right' : 'bottom-left']"
        style="position: relative"
        :style="{transform: `scale(${scaleCoef})`}"
      >
        <power-stick
          v-show="isMobile()"
          v-if="tutorialState.buttons.showJoystick"
          :only-horizontal="movementState.onlyHorizontal"
          :glow="true"
          :is-scaled="false"
        />
        <movement-buttons
          v-show="isMobile()"
          v-if="tutorialState.buttons.showMovementButtons"
          style="pointer-events: none"
          :glow="true"
          :is-scaled="false"
        />
      </div>
      <section
        :class="[!gameSettingsState.isLeft ? 'bottom-right' : 'bottom-left']"
        class="buttons-set-wrapper"
        :style="[
          {transform: `scale(${scaleCoef})`, position: 'absolute', right: '0', bottom: '0'},
          smallButtonsLeftFix
        ]"
      >
        <mobile-button
          v-if="tutorialState.buttons.showJoystick"
          v-show="isMobile()"
          type="shoot"
          :glow="true"
          :is-scaled="false"
        />
        <section
          v-show="isMobile()"
          class="movement-buttons-section"
        >
          <arrow-animation
            v-if="tutorialState.buttons.showMovementButtons || tutorialState.buttons.showJoystick"
            position="bottom"
            style="position:absolute; width: 580px; height: 460px; bottom: 0;"
            :is-scaled="false"
          />
          <movement-buttons
            v-if="tutorialState.buttons.showMovementButtons"
            style="pointer-events: none"
            orientation="vertical"
            :glow="true"
            :is-scaled="false"
          />
        </section>
        <div
          v-if="isMobile()"
          class="sub-positions buttons-position"
        >
          <arrow-animation
            v-if="tutorialState.buttons.showSprint"
            position="bottom"
            style="position:absolute; width: calc(50% + 128px); height: 108px; bottom: 2%;"
            :is-scaled="false"
          />
          <small-action-button
            v-show="tutorialState.buttons.showSprint"
            type="biatlon-speed"
            class="bar-left"
            :style="basicStyle"
            :glow="true"
            :pie-percent="gamePhaseState.sprintBarState"
            :color="gamePhaseState.sprintButtonColor"
            :letter="isMobile() ? '' : 'q'"
            :is-scaled="false"
            @click.stop=""
            @mousedown.stop="performAction2"
            @mouseup.stop="endAction2"
            @touchstart.stop="performAction2"
            @touchend.stop="endAction2"
          />
          <arrow-animation
            v-if="tutorialState.buttons.showDownhill"
            position="bottom"
            style="position:absolute; width: calc(50% + 128px); height: 108px; bottom: 2%; margin-left: 148px;"
            :is-scaled="false"
          />
          <small-action-button
            v-show="tutorialState.buttons.showDownhill"
            type="biatlon-tuck"
            class="bar-right"
            :style="basicStyle"
            :glow="true"
            :pie-percent="100"
            :color="gamePhaseState.downhillButtonColor"
            :letter="isMobile() ? '' : 'e'"
            :is-scaled="false"
            @click.stop=""
            @mousedown.stop="performAction"
            @mouseup.stop="endAction"
            @touchstart.stop="performAction"
            @touchend.stop="endAction"
          />
        </div>
      </section>
    </section>
  </section>
  <section
    v-if="!isMobile()"
    class="nomobile-small-buttons-tutorial"
  >
    <div
      class="small-action-buttons-sub-positions is-not-mobile top-right"
      :style="{transform: `scale(${scaleCoef})`}"
    >
      <arrow-animation
        v-if="tutorialState.buttons.showSprint"
        position="bottom"
        style="position:absolute; height: 100%; width: 50%; margin-right: 50%; bottom: 2%;"
        :is-scaled="false"
      />
      <div class="bar">
        <section
          v-if="tutorialState.buttons.showSprint"
          class="tutorial-keyboard-header"
        >
          <shrinking-text-box
            :text="$t('webInfoSprint')"
            :font-size="28"
            :width="150"
            :height="34"
          />
        </section>
        <small-action-button
          v-show="tutorialState.buttons.showSprint"
          type="biatlon-speed"
          :glow="true"
          :pie-percent="gamePhaseState.sprintBarState"
          :color="gamePhaseState.sprintButtonColor"
          :letter="isMobile() ? '' : 'q'"
          :is-scaled="false"
          @click.stop=""
          @mousedown.stop="performAction2"
          @mouseup.stop="endAction2"
          @touchstart.stop="performAction2"
          @touchend.stop="endAction2"
        />
      </div>
      <arrow-animation
        v-if="tutorialState.buttons.showDownhill"
        position="bottom"
        style="position:absolute; height: 100%; width: 50%; margin-left: calc(50% + 70px); bottom: 2%;"
        :is-scaled="false"
      />
      <div
        class="bar"
        style="padding-left:70px;"
      >
        <section
          v-if="tutorialState.buttons.showDownhill"
          class="tutorial-keyboard-header"
        >
          <shrinking-text-box
            :text="$t('webInfoTuck')"
            :font-size="28"
            :width="150"
            :height="34"
          />
        </section>
        <small-action-button
          v-show="tutorialState.buttons.showDownhill"
          type="biatlon-tuck"
          :glow="true"
          :pie-percent="100"
          :color="gamePhaseState.downhillButtonColor"
          :letter="isMobile() ? '' : 'e'"
          :is-scaled="false"
          @click.stop=""
          @mousedown.stop="performAction"
          @mouseup.stop="endAction"
          @touchstart.stop="performAction"
          @touchend.stop="endAction"
        />
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import {
  WindowAspect,
  SmallActionButton,
  ArrowAnimation,
  MovementButtons,
  PowerStick,
  MobileButton,
  ShrinkingTextBox,
  gameSettingsState
} from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import {
  gamePhaseState,
  movementState,
  tutorialState
} from '@/stores'

export default defineComponent({
  name: 'TutorialButtons',
  components: {
    SmallActionButton,
    ArrowAnimation,
    MovementButtons,
    PowerStick,
    MobileButton,
    ShrinkingTextBox
  },
  mixins: [WindowAspect],
  data() {

    return {
      tasks: [],
      gamePhaseState: gamePhaseState(),
      movementState: movementState(),
      tutorialState: tutorialState(),
      gameSettingsState: gameSettingsState(),
    }

  },
  computed: {
    basicStyle() {

      return {
        'align-items': 'flex-end',
        padding: '0 0 10px'
      }

    },
    transformCoef() {

      return { transform: `scale(${this.scaleCoef})` }

    },
    smallButtonsLeftFix() {

      if (!this.isMobile()) return { width: '100%' }
      if (gameSettingsState().isLeft && this.isMobile()) {

        return { left: '0' }

      }
      return {}

    }
  },
  methods: {
    performAction() {

      tutorialFlow.tutorialOnPressedDownhill()

    },
    endAction() {

      tutorialFlow.tutorialOnUnpressedDownhill()


    },
    performAction2() {

      tutorialFlow.tutorialOnPressedSprint()

    },
    endAction2() {

      tutorialFlow.tutorialOnUnpressedSprint()

    }
  }
})
</script>

<!-- TU nemoze byt scoped, pokial bude riesene .small-action-button-wrapper v tomto file -->
<style lang="less">

.nomobile-small-buttons-tutorial {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 375px;
    height: 375px;

    .bar {
        position: relative;
    }

    .arrow-wrapper {
        transform-origin: 50% 0%;
    }
    .bottom-right {
    transform-origin: bottom right;
    }
    .bottom-left {
    transform-origin: bottom left;
    }
}

.tutorial {

    .positioner {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .flex {
        display: flex;
        justify-content: space-between;
    }

    .row {
        flex-direction: row;
    }

    .row-reverse {
        flex-direction: row-reverse;
    }

    .buttons-set-wrapper {
        position: relative;
        width: 580px;
        height: 580px;
    }

    .buttons-position {
        position: absolute;
        top: -60px;
        left: 0;
        width: 100%;
        height: 200px;
    }

    .tutorial-anne {
        bottom: 0;
    }

    .sub-positions {

        .bar-right {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            width: 50%;
            height: 200px;
            position: absolute;
            right: 0;

            .small-action-button-wrapper {
                pointer-events: auto;
                justify-content: flex-start;
                width: 100%;
                height: 100%;
                align-items: flex-end;
                padding: 0 20px;
            }
        }
        .bar-left {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 50%;
            height: 200px;
            position: absolute;
            left: 0;

            .small-action-button-wrapper {
                pointer-events: auto;
                justify-content: flex-end;
                width: 100%;
                height: 100%;
                align-items: flex-end;
                padding: 0 20px;
            }
        }
    }
    .bottom-right {
        transform-origin: bottom right;
    }
    .bottom-left {
        transform-origin: bottom left;
    }
    .top-right {
        transform-origin: top right;
    }
}
</style>
