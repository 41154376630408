import {
  game,
  THREE,
  gsap,
  modes
} from '@powerplay/core-minigames'
import { shootingPhaseConfig } from '../config/shootingPhaseConfig'
import { shootingDirectionManager } from '../shootingDirectionManager'
import { ShootingTargetsTypes } from '../types'
import { bulletsTargetsBoxState } from '@/stores'

/**
 * manazer tercov pri strelbe
 */
export class ShootingTargetsManager {

  /** targety */
  private targetsMeshes: THREE.Mesh[] = []

  /** Aktualny typ tercov */
  private actualTargetsType = 0

  /** Retaz typov tercov */
  private targetsTypesChain: ShootingTargetsTypes[] = []

  /**
   * Inicializacia
   */
  public init(): void {

    if (this.targetsMeshes.length > 0) {

      this.resetTargets()
      this.targetsMeshes = []
      this.actualTargetsType = 0

    }

    // random pre disciplinu
    this.targetsTypesChain = [
      Math.random() < 0.5 ? ShootingTargetsTypes.prone : ShootingTargetsTypes.standing
    ]

    // TODO: ked bude denna liga, tak to pojde bud ako vyssie trening alebo opacne
    if (modes.isTrainingMode()) {

      this.targetsTypesChain = [ShootingTargetsTypes.prone, ShootingTargetsTypes.standing]

    }
    if (modes.isTutorial()) {

      this.targetsTypesChain = [ShootingTargetsTypes.prone]

    }

    bulletsTargetsBoxState().targetsType = this.getActualShootingType()

  }

  /**
   * Nastavenie viditelnosti vsetkych tienov pre zaklapky
   * @param visibility - True, ak ma byt viditelne
   */
  public setVisibilityAllCoverPlanes(visibility: boolean): void {

    for (let id = 1; id <= 5; id++) {

      this.setVisibilityOneCoverPlane(visibility, ShootingTargetsTypes.prone, id)
      this.setVisibilityOneCoverPlane(visibility, ShootingTargetsTypes.standing, id)

    }

  }

  /**
   * Vratenie meshu konkretnej zaklapky
   * @param type - Typ tercu
   * @param id - Poradove cislo tercu
   * @returns Mesh
   */
  private getTargetCoverPlaneMesh(type: ShootingTargetsTypes, id: number): THREE.Mesh {

    const meshName = `Target_Cover_${type}_${id}Plane`
    return game.getMesh(meshName)

  }

  /**
   * Nastavenie viditelnosti tienu pre jednu konkretnu zaklapku
   * @param visibility - True, ak ma byt viditelne
   * @param type - Typ tercika
   * @param id - poradove cislo tercika
   */
  private setVisibilityOneCoverPlane(
    visibility: boolean,
    type: ShootingTargetsTypes,
    id: number
  ): void {

    const targetCoverPlane = this.getTargetCoverPlaneMesh(type, id)
    targetCoverPlane.visible = visibility

  }

  /**
   * Nastavenie rotacie tienu konkretnej zaklapky
   * @param type - Typ tercu
   * @param id - Poradove cislo tercu
   * @returns Mesh
   */
  private setRotationOneCoverPlane(type: ShootingTargetsTypes, id: number): void {

    const targetCoverPlane = this.getTargetCoverPlaneMesh(type, id)
    targetCoverPlane.rotation.z = Math.PI / 2
    targetCoverPlane.updateMatrix()

  }

  /**
   * Vratenie aktualneho typu tercov
   * @returns Aktualny typ tercov
   */
  public getActualShootingType(): ShootingTargetsTypes {

    return this.targetsTypesChain[this.actualTargetsType]

  }

  /**
   * hladame intersect mieritka a terca
   * @param isAction - ci sme vykonali akciu
   * @returns true ak sme trafili terc
   */
  public findIntersects(): boolean {

    for (let i = 0; i < this.targetsMeshes.length; i++) {

      if (this.isIntersect(this.targetsMeshes[i])) {

        const hitTarget = this.targetsMeshes.splice(i, 1)[0]
        this.setTargetIsHit(hitTarget)
        return true

      }

    }

    return false

  }

  /**
   * po trafeni terca otocime mesh a vyhodime "hitbox"
   */
  private setTargetIsHit(target: THREE.Mesh): void {

    const id = target.name.split('_')[1]
    const visibleMeshName = `Target_Cover_${this.getActualShootingType()}_${id}`
    const targetCover = game.getMesh(visibleMeshName)

    targetCover.matrixAutoUpdate = true

    // rotacia zaklapky
    gsap.to(targetCover.rotation, {
      onComplete: () => {

        targetCover.rotation.z = Math.PI / 2
        targetCover.updateMatrix()
        targetCover.matrixAutoUpdate = false

      },
      z: Math.PI / 2,
      duration: 0.3
    })

    // zobrazenie tienu pod zaklapkou kvoli lepsej viditelnosti
    gsap.to({}, {
      onComplete: () => {

        this.setRotationOneCoverPlane(this.getActualShootingType(), Number(id))
        this.setVisibilityOneCoverPlane(true, this.getActualShootingType(), Number(id))

      },
      duration: 0.2
    })

    // trafeny terc vyhodime z pola a sceny
    this.removeObjectFromScene(target)

    bulletsTargetsBoxState().targets[parseInt(id) - 1] = true

  }

  /**
   * zistime ci mame intersect s konkretnym cielom
   * @param target - mesh ciela
   * @returns - ci je intersect
   */
  private isIntersect(target: THREE.Mesh): boolean {

    const targetPosition = target.position
    const targetRadius = shootingPhaseConfig.targetRadius[this.getActualShootingType()]

    const targetPointPosition = shootingDirectionManager.targetPoint.position
    const targetPointRadius = shootingPhaseConfig.scopeRadius

    return targetPosition.distanceTo(targetPointPosition) <= (targetRadius + targetPointRadius)

  }

  /**
   * create targets
   */
  public createTargets(): void {

    shootingPhaseConfig.targetPositions[this.getActualShootingType()].forEach((value) => {

      const geometry = new THREE.SphereGeometry(shootingPhaseConfig.targetRadius[this.getActualShootingType()])
      const material = new THREE.MeshBasicMaterial({
        color: new THREE.Color(0xFF0000)
      })

      const mesh = new THREE.Mesh(geometry, material)
      mesh.name = `target_${value.id}`
      mesh.matrixAutoUpdate = false

      mesh.position.set(
        value.position.x,
        value.position.y,
        value.position.z
      )
      mesh.updateMatrix()

      if (!shootingPhaseConfig.debug.showTargets) mesh.visible = false

      this.targetsMeshes.push(mesh)
      game.scene.add(mesh)

    })

  }

  /**
   * Vyhodime terciky zo sceny
   */
  public removeObjectsFromScene(): void {

    this.targetsMeshes.forEach((value) => {

      this.removeObjectFromScene(value)

    })

  }

  /**
   * odstranenie konkretneho meshu zo sceny
   * @param object - THREE.Mesh
   */
  private removeObjectFromScene(object: THREE.Mesh): void {

    object.geometry.dispose()

    if (object.material instanceof Array) {

      object.material.forEach((material: THREE.Material) => material.dispose())

    } else {

      object.material.dispose()

    }

    game.scene.remove(object)

  }

  /**
   * zresetujeme rotacie viditelnych tercov
   */
  public resetTargetsRotation(): void {

    for (let i = 1; i <= 5; i++) {

      const visibleMeshName = `Target_Cover_${this.getActualShootingType()}_${i}`
      const targetCover = game.getMesh(visibleMeshName)
      targetCover.rotation.z = 0
      targetCover.updateMatrix()

    }

  }

  /**
   * Nastavenie dalsieho typu tercov v poradi
   */
  public setNextTargetsType(): void {

    // musime dat dalsi prvok v poradi retaze, musime ale dbat na to, aby nebolo neexistujuce
    this.resetTargets()
    this.actualTargetsType++
    if (this.actualTargetsType >= this.targetsTypesChain.length) this.actualTargetsType = 0

    bulletsTargetsBoxState().targetsType = this.getActualShootingType()

  }

  /**
   * Metoda na reset targetov
   */
  public resetTargets(): void {

    this.setVisibilityAllCoverPlanes(false)
    this.resetTargetsRotation()
    this.targetsMeshes.forEach(target => {

      this.removeObjectFromScene(target)

    })
    this.targetsMeshes = []

    this.createTargets()

  }

}

export const shootingTargetsManager = new ShootingTargetsManager()
