import {
  CANNON,
  THREE
} from '@powerplay/core-minigames'

/** Konfig pre nastavenie fyziky a aj celej hry */
export const gameConfig = {

  /** Pocet pokusov */
  numberOfAttempts: 1,

  /**
   * How much to damp the body velocity each step. 0-1
   * DEFAULT: 0.01
   */
  linearDamping: 0.001,

  /**
   * Hmotnost lyziara
   * DEFAULT: 1
   */
  playerMass: 90,

  /**
   * Trenie lyziara s kopcom
   * DEFAULT: 0.3
   */
  frictionHillPlayer: /* 0.01 */ 0.001,

  /**
   * Tzv bounciness alebo skakavost lyziara vzhladom na kopec
   * DEFAULT: 0.3
   */
  restitutionHillPlayer: 0,

  /**
   * Relaxation time of the produced friction equations
   * DEFAULT: 3
   */
  frictionEquationRelaxationHillPlayer: 3,

  /**
   * Stiffness of the produced friction equations
   * DEFAULT: 1e7
   */
  frictionEquationStiffnessHillPlayer: 10000000,

  /**
   * Relaxation time of the produced contact equations
   * DEFAULT: 3
   */
  contactEquationRelaxationHillPlayer: 3,

  /**
   * Stiffness of the produced contact equations
   * DEFAULT: 1e7
   */
  contactEquationStiffnessHillPlayer: 10000000,

  /**
   * Gravitacia sveta
   * DEFAULT: (0, -14.31, 0)
   */
  gravitation: new CANNON.Vec3(0, -9.81, 0),

  /**
   * Ako velmi zoberieme do uvahy fyziku sveta pri zmene uhla, aby nerobila taky odpor, resp aby
   * mali vacsiu vahu inputy hraca. Napr ked je uhol 2.14 a podla fyziky by sa mal zmenit na 2.02,
   * tak pri tejto hodnote 0.2 dame uhol 2.116 a tym padom viac zostane nas smer nez ten, ktory
   * nam urcuje fyzika
   * DEFAULT: 0.2
   */
  percentPhysicsRotationChange: 0,

  /**
   * Koeficient pre gravitaciu vo vzduchu pri malej vzdialenosti, ktora je urcena cez maxDistance
   * nizsie, dany koeficient sa prirata ku default gravitacii
   * DEFAULT: -15
   */
  gravityCoefInAirNearTrack: 0,

  /**
   * Minimalna vzdialenost od povrchu kopca pre specialnu gravitaciu
   * DEFUALT: 0.3
   */
  minDistanceForGravityCoefinAirNearTrack: 0.3,

  /**
   * Maximalna vzdialenost od povrchu kopca pre specialnu gravitaciu
   * DEFUALT: 1
   */
  maxDistanceForGravityCoefinAirNearTrack: 1,

  /**
   * offset prilepenia hraca na svah - iba na starte
   */
  playerModelOffsetStart: 0.1,

  /**
   * offset prilepenia hraca na svah
   */
  playerModelOffset: 0.3,

  /**
   * Pociatocny smer pre velocity
   */
  startVelocityDirection: new CANNON.Vec3(0, 0, 1),

  /** nastavenie kamery aplikovane po starte. */
  cameraConfig: {

    // ci chceme zmeny aplikovat
    enabled: true,

    /*
     * ako daleko od hraca ma byt kamera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealOffset: new THREE.Vector3(0, 2, -2.5),

    /*
     * ako daleko od hraca ma byt bod na ktory sa kamera pozera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealLookAt: new THREE.Vector3(0, 0, 2),

    /*
     * ako rychlo ma kamera nasledovat hraca
     * typ number | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    coefSize: undefined,

    // velkost lerpu pri zmene kamery
    changeLerp: 1

  },

  /**
   * starting position
   * POZN.: cannon ve3 to je kvoli tomu, aby sedel vypocet distanceTo aj v cannon svete
   */
  startPosition: new CANNON.Vec3(-5.6, 1, -20.4),

  /**
   * defaultna rychlost animacii
   */
  defaultAnimationSpeed: 1 / 2,

  /**
   * pocet framov medzi kazdou aktualizaciou sprint baru
   */
  sprintBarFrameRate: 6,

  /**
   * fill rate sprintbaru
   */
  sprintBarFillRate: 1,

  /**
   * fill rate sprintbaru pri downhill
   */
  sprintBarDownhillIncreaseRate: 2,

  /**
   * Znizovanie hodnoty sprintBaru
   */
  sprintBarDecreaseRate: 4,

  /**
   * Najnizsia rychlost kde je crouch povoleny
   */
  smallestSpeedToCrouch: 3,

  /**
   * Koeficient pre lerp normal na kopci, aby nebol prechod medzi normalami "hranaty"
   */
  hillNormalLerpCoef: 0.01,

  /**
   * Koeficient pre lerp hracovej rotacie, aby tiez nebol prechod medzi rotaciami "hranaty"
   */
  playerRotationLerpCoef: 0.1,

  /**
   * Koeficient pre lerp velocity na Y osi kvoli traseniu kamery
   */
  velocityYLerpCoef: 0.05,

  /**
   * Penalizacia v sekundach
   */
  penaltySeconds: 10,

  /**
   * po starte rovno strelba
   */
  startWithShooting: false,

  /**
   * Rychlost casu pocas behu - prepisuje sa podla atributov automaticky !!!! toto je iba
   * zakladna hodnota
   */
  timeSpeedRunning: 6,
  timeSpeedRunningUnder100: 7.89,

  /**
   * Koeficienty pre plynutie casu podla atributov
   */
  timeSpeedRunningCoefs: {
    above1000: 0.00035,
    under1000: 0.00022,
    under100: 0.0005
  },

  /**
   * Pocet sekund po ktorych ho vyhodime z hry ak je mimo trate
   */
  outOfBoundsSeconds: 3,

  /**
   * Hlbka v metroch kolko pod tratou ma byt, aby sa pocital ako mimo trate
   */
  depthOutOfBounds: 0.5,

  /**
   * Ci mam aktivny skip do ciela a na akej pozicii to zacina
   */
  skipToFinish: {

    active: false,
    position: new CANNON.Vec3(11, 1, -20)

  },

  /** system na zabranenie nahleho spomalenia v tuck */
  antiSlowEnabled: true

}
