import { shootingPhaseConfig } from '@/app/config/shootingPhaseConfig'
import { ShootingTargetsTypes } from '@/app/types'
import { defineStore } from 'pinia'

export interface BulletsTargetsBoxState {
  visible: boolean
  bullets: number
  targets: Array<boolean>
  showBlackScreen: boolean,
  targetsType: ShootingTargetsTypes
}

const initialState = (): BulletsTargetsBoxState => ({
  visible: false,
  bullets: 0,
  targets: [false, false, false, false, false],
  showBlackScreen: false,
  targetsType: ShootingTargetsTypes.standing
})

export const bulletsTargetsBoxState = defineStore('bulletsTargetsBoxState', {
  state: initialState,
  actions: {
    resetForTraining() {

      this.bullets = shootingPhaseConfig.attemptCount
      this.targets = new Array(shootingPhaseConfig.attemptCount).fill(false)

    }
  }
})
