/** Mena vsetkych modelov, ktore sa pouzivaju v minihre */
export enum ModelsNames {
  skier = 'skier',
  hill = 'hill',
  checkpoint = 'marker',
  gates = 'gates',
  gatesPositions = 'Branky_Positions',
  physics = 'physics_track',
  rifle = 'rifle'

}

/** Mena vsetkych textur, ktore sa pouzivaju v minihre */
export enum TexturesNames {
  skierRacePrefix = 'skier_race_',
  skierRaceWhiteMan = 'male/skier_race_white',
  skierRaceMulattoMan = 'male/skier_race_mulatto',
  skierRaceBlackMan = 'male/skier_race_black',
  skierRaceWhiteWoman = 'female/skier_race_white',
  skierRaceMulattoWoman = 'female/skier_race_mulatto',
  skierRaceBlackWoman = 'female/skier_race_black',
  skierClothes = 'skier_clothes',
  hill = 'Atlas',
  transparent = 'TransparentTexture',
  mountain = 'mountain',
  skybox = 'Skybox',
  ads = 'logo',
  flags = 'flags',
  track = 'Track',
  people = 'People',
  lightmapHill = 'LightmapHill',
  staticPeople = 'StaticPeople',
  rifle = 'rifle',
  rocks = 'Rock',
  impact = 'impact',
  startAds = 'logoTransparent',
  snowParticle = 'snowSSbase',
  targetCoverShadow = 'targetCoverShadow'
}

/** Mena vsetkych materialov, ktore sa pouzivaju v minihre */
export enum MaterialsNames {
  skier = 'skier',
  hill = 'Atlas1',
  transparent = 'TransparentTexture',
  mountain = 'mountain',
  ads = 'ads',
  flags = 'flags',
  track = 'track',
  people = 'People',
  staticPeople = 'StaticPeople',
  onlyVertexColor = 'OnlyVertexColor',
  rifle = 'rifle',
  rocks = 'rocks',
  impact = 'impact',
  startAds = 'StartAds',
  targetCoverShadow = 'targetCoverShadow',
  targets = 'targets',
  targetCover = 'targetCover'
}

/** Mena vsetkych animacii hraca */
export enum PlayerAnimationsNames {
  lunge = 'lunge',
  prepare = 'prepare',
  prestart = 'prestart',
  start = 'start',
  sprint = 'sprint',
  v1Skating = 'V1 skating 3',
  v2Skating = 'V2 skating',
  stop1 = 'stop 1',
  stop2 = 'stop 2',
  shootingComeInPreStop = 'prichod na strelnicu',
  downhillLeft = 'zjazd L',
  downhillRight = 'zjazd R',
  downhill = 'zjazd',
  neutral = 'neutral',
  happy = 'happy',
  v3Skating = 'V3 skating start'
}

/** Konfig pre zakladne fyzicke veci */
export interface GameConfig {
  linearDamping: number
  playerMass: number
  frictionHillPlayer: number
  restitutionHillPlayer: number
  frictionEquationRelaxationHillPlayer: number
  frictionEquationStiffnessHillPlayer: number
  contactEquationRelaxationHillPlayer: number
  contactEquationStiffnessHillPlayer: number
}

/**
 * Specialne data z init requestu
 */
export interface SpecialDataFromInit {

  split: number[]

}
