import {
  AudioNames,
  DisciplinePhases
} from '@/app/types'
import {
  gsap,
  audioManager,
  modes
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import { StartPhaseManager } from './StartPhase'

export class StartSoundSystem {

  /** casovac pipnuti */
  private soundTimer = 0

  /** pocitadlo pipnuti */
  private soundCounter = 0

  /** tween prveho pipnutia */
  private firstSoundTween!: gsap.core.Tween

  /** pocet pipnuti po starte */
  public beepsAfterStart = 0

  /**
   * Vratenie prveho tweenu pre zvuky
   * @returns Tween
   */
  public getFirstSoundTween(): gsap.core.Tween {

    return this.firstSoundTween

  }

  /**
   * Vratenie pocitadla pipnuti
   * @returns Pocet pipnuti
   */
  public getSoundCount(): number {

    return this.soundCounter

  }

  /**
   * Vratenie casovaca pipnuti
   * @returns Hodnota casovaca pipnuti
   */
  public getSoundTimer(): number {

    return this.soundTimer

  }

  /**
   * Odstartovanie zuvku
   */
  public startSound(): void {

    if (!modes.isTutorial()) {

      this.firstSoundTween = gsap.to({}, {
        onComplete: this.makeBeep,
        callbackScope: this,
        duration: 1
      })

    }

  }

  /**
   * Pustenie zvuku pipnutia
   */
  private makeBeep = (): void => {

    let audioName = AudioNames.countdownBeepShort
    if (this.soundCounter === 5) audioName = AudioNames.countdownBeepLong

    const startPhase = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
    if (startPhase.ended) this.beepsAfterStart++

    audioManager.play(audioName)
    audioManager.changeAudioVolume(audioName, (1 - (0.1 * this.beepsAfterStart)))

  }

  /**
   * Spustenie tweenu pre jedno pipnutie
   */
  public runSoundTween(): void {

    this.soundCounter += 1

    if (this.soundCounter <= 5) {

      gsap.timeline().to(this, {
        onComplete: this.makeBeep,
        callbackScope: this,
        soundTimer: 100,
        duration: 0.5,
        ease: 'power1.out'
      }).to(this, {
        onComplete: this.runSoundTween,
        callbackScope: this,
        soundTimer: 0,
        duration: 0.5,
        ease: 'power1.in'
      })

    } else {

      /*
       * TODO: toto lepsie spravit, nech to tu nemusi byt takto - ale mozno sa upravi, ked
       * to vyssie bude optimalizovane
       */
      gsap.timeline().to(this, {
        callbackScope: this,
        soundTimer: 100,
        duration: 0.5,
        ease: 'power1.out'
      }).to(this, {
        onComplete: this.runSoundTween,
        callbackScope: this,
        soundTimer: 0,
        duration: 0.5,
        ease: 'power1.in'
      })

    }

  }

  /**
   * Zresetovanie veci
   */
  public reset(): void {

    this.soundCounter = 0
    this.soundTimer = 0
    this.beepsAfterStart = 0

  }

}

export const startSoundSystem = new StartSoundSystem()
