<template>
  <bullets-targets-box
    v-if="bulletsTargetsBoxState.visible"
    :bullets="bulletsTargetsBoxState.bullets"
    :targets="bulletsTargetsBoxState.targets"
    :targets-type="bulletsTargetsBoxState.targetsType"
  />
</template>

<script lang="ts">
// External Package codes
import { defineComponent } from 'vue'

/*
 * Internal Package codes
 * import {} from '@powerplay/core-minigames'
 */
import { BulletsTargetsBox } from '@powerplay/core-minigames-ui'
import { bulletsTargetsBoxState } from '@/stores'

export default defineComponent({
  name: 'StructureComponent',
  components: {
    BulletsTargetsBox
  },
  data() {

    return {
      bulletsTargetsBoxState: bulletsTargetsBoxState(),
    }

  },
})
</script>

<style lang="less" scoped>
</style>
