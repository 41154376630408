import type { AnimationsSettingRepository } from '@powerplay/core-minigames'
import { PlayerAnimationsNames } from '../types'
/**
 * Konfig pre animacie
 */
export const animationsConfig: AnimationsSettingRepository = {

  [PlayerAnimationsNames.lunge]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.sprint]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.v1Skating]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prepare]: {
    loop: true,
    starting: true
  },
  [PlayerAnimationsNames.v2Skating]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.downhillLeft]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.start]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.downhillRight]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.downhill]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.neutral]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.happy]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.prestart]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.stop1]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.stop2]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.shootingComeInPreStop]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.v3Skating]: {
    loop: true,
    starting: false
  }
}
