import {
  corePhasesManager,
  modes,
  playersManager,
  requestManager,
  timeManager,
  type TrainingDataFromResultsRequest,
  trainingManager,
  gameStats,
  type TournamentDataFromResultsRequest,
  gsap
} from '@powerplay/core-minigames'
import { shootingPhaseConfig } from './config/shootingPhaseConfig'
import { player } from './entities/player'
import { triggers } from './entities/trigger/Trigger'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { tutorialObjectives } from './modes/tutorial/TutorialObjectives'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import type { ShootingPhaseManager } from './phases/ShootingPhase/ShootingPhase'
import type { StartPhaseManager } from './phases/StartPhase/StartPhase'
import {
  DisciplinePhases,
  type SaveResultsDataToSend
} from './types'
import {
  blurState,
  tableState,
  trainingResultsState
} from '@/stores'
import { stateManager } from './StateManager'
import { waitingState } from '@powerplay/core-minigames-ui'

/**
 * Trieda pre koniec discipliny
 */
export class EndManager {

  /** ci uz bol result poslany alebo nie */
  private resultSent = false

  /**
   * Poslanie requestu pre konecne logovanie
   */
  public sendLogEnd(): void {

    // ak uz mame nastavene, tak uz viac nenastavujeme
    if (Object.keys(gameStats.getDisciplineDataToLog()).length > 0) return

    const startPhaseManager = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager

    // zaznamename nejake info pre logy
    gameStats.setDisciplineDataToLog({
      time: playersManager.players[0].resultsArr?.[corePhasesManager.disciplineActualAttempt - 1].main || 0,
      penalty: timeManager.getPenaltyInfo().seconds,
      dnf: playersManager.dnf,
      outOfBounds: player.outOfBounds,
      split: playersManager.dnf ? [] : triggers.splitTimeManager.getAllSplitTimes(),
      qualities: {
        start: startPhaseManager.clickedPower / 100
      },
      playerPosition: playersManager.getPlayerActualPosition(),
      trainingTasks: modes.isTrainingMode() ?
        trainingManager.getTrainingTasks().map(task => task.value) :
        [],
      tutorialData: modes.isTutorial() ? this.getTutorialLogs() : []
    })

    console.log('LOG to send', gameStats.getDisciplineDataToLog())

  }

  /**
   * ziskame pole tutorialovych logov
   *
   * @returns - pole tutorialovych logov
   */
  private getTutorialLogs(): (number | boolean)[] {

    /**
     * repeatedShooting - Kolkokrat hrac opakoval strelbu [number]
     * targetHits - Kolko tercov trafil [number]
     * completed - Ci hrac uspesne dokoncil vsetky ulohy [boolean]
     * exited - Ci bol ukonceny tutorial [boolean]
     */

    const shootingPhase =
      disciplinePhasesManager.getDisciplinePhaseManager(DisciplinePhases.shooting) as ShootingPhaseManager

    return [
      tutorialFlow.shootingAttemptCount, // repeatedShooting
      shootingPhase.getHitTargets(), // targetHits
      tutorialObjectives.isAllObjectivesPassed(), // completed
      disciplinePhasesManager.prematureEnded // exited
    ]

  }

  /**
   * Vybratie dat a poslanie do funkcie z core-minigames
   */
  public sendSaveResult(): void {

    // ak uz bol result poslany, neposielame ho znova
    if (this.resultSent) return

    this.resultSent = true

    // TODO TEMP - zatial takto, ked bude hotovy tutorial, tak sa to bude posielat tam
    requestManager.sendTutorialRequest()
    if (modes.isTutorial()) return

    const missedTargetsArr = playersManager.players[0].resultsArr?.[
      corePhasesManager.disciplineActualAttempt - 1
    ]?.second as number[] ?? []

    const data: SaveResultsDataToSend = {
      time: playersManager.players[0].resultsArr?.[
        corePhasesManager.disciplineActualAttempt - 1
      ].main || 0,
      penalty_time: timeManager.getPenaltyInfo().seconds,
      positions: playersManager.getPlayersPositions(),
      dnf: playersManager.dnf,
      dsq: false,
      missedTargets: [missedTargetsArr[0]],
      // TODO: aj toto je zatial natvrdo, neskor doplnime o viac strelb
      shotdownTargets: shootingPhaseConfig.attemptCount - missedTargetsArr[0],
      split_times: playersManager.dnf ? [] : triggers.splitTimeManager.getAllSplitTimes()
    }

    if (modes.isTrainingMode()) {

      data.trainingResults = trainingManager.getTrainingTasks().map(task => task.value)

    }

    console.log('data to send', data)

    if (modes.isTournament()) {

      waitingState().isWaiting = true
      blurState().$patch({
        isActive: true,
        isTable: true
      })
      tableState().$patch({
        resultText: modes.isTournament() || modes.isDailyLeague() ? 'provisionalResults' : 'finalStandings',
        showTable: true,
        activeState: false,
        dataTable: [],
        isStartList: false,
      })

    }

    requestManager.sendSaveResultsRequest(
      (dataCallback: TrainingDataFromResultsRequest | TournamentDataFromResultsRequest | unknown) => {

        console.log('saveRequest Successful')

        if (modes.isTrainingMode()) {

          trainingResultsState().$patch({
            data: dataCallback as TrainingDataFromResultsRequest,
            bestScore: trainingManager.bestScore,
            dataSet: true
          })

        }

        if (modes.isTournament()) {

          if (Number(import.meta.env.VITE_APP_LOCAL) === 1) {

            gsap.to({}, {
              onComplete: () => {

                disciplinePhasesManager.setOpponentsForFinishTable(dataCallback as TournamentDataFromResultsRequest)
                stateManager.afterAllRequestsDone()

              },
              duration: 5
            })

          } else {

            disciplinePhasesManager.setOpponentsForFinishTable(dataCallback as TournamentDataFromResultsRequest)

          }

        }

      },
      JSON.stringify(data)
    )

  }

  /**
   * Reset result
   */
  public reset(): void {

    this.resultSent = false

  }

}

export const endManager = new EndManager()
