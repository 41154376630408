<template>
  <game-biathlon-component />
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import GameBiathlonComponent from './components/GameBiathlonComponent.vue'

export default defineComponent({
  components: {
    GameBiathlonComponent
  },
})

</script>
