import { audioGameConfig } from '@/app/config'
import { heartRateConfig } from '@/app/config/heartRateConfig'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  AudioNames,
  DisciplinePhases
} from '@/app/types'
import {
  audioManager,
  fpsManager,
  modes
} from '@powerplay/core-minigames'
import { player } from '.'
import { heartRateState } from '@/stores'

/** Klassa na heart rate manager */
export class HeartRateManager {

  /** Srdcovy tep - ta hodnota ako rychlo bije srdce: https://en.wikipedia.org/wiki/Heart_rate */
  private heartRate = heartRateConfig.minRate

  /** Specificka hodnota ktoru vyhodnocujeme pre rychlostny bar */
  private speedBarHeartRate = 150

  /** FrameRate counter */
  private frameOfGame = 0

  /** Zmena heart rate */
  private heartRateChange = 0

  /** Ziskanie heart rateu na pouzitie pre ine moduly */
  public getHeartRate(): number {

    if (modes.isTrainingMode()) return 120
    return this.heartRate

  }

  public setHeartRate(value: number): this {

    this.heartRate = value
    return this

  }

  /** jedna sekunda */
  private oneSecPassed(): boolean {

    this.frameOfGame++
    return this.frameOfGame % fpsManager.fpsLimit === 0

  }

  /**
   * Zistenie ci hra este neodstartovala
   * @returns True, ak neostartovala este hra
   */
  private gameNotStarted(): boolean {

    return disciplinePhasesManager.oneOfPhaseIsActual([
      DisciplinePhases.start,
      DisciplinePhases.preStart,
      DisciplinePhases.end,
      DisciplinePhases.finish
    ])

  }

  /** Zistenie ci je faza shooting */
  private isShootingPhase(): boolean {

    return disciplinePhasesManager.getActualPhase() === DisciplinePhases.shooting

  }

  /** Kalkulacia heartrateu v sprinte */
  private calculateSprintingHeartRate(): void {

    if (player.isSprinting) this.heartRateChange = heartRateConfig.sprintPhaseAdd

  }

  /** Kalkulacia heartrateu v couchingu */
  private calculateDownhillHeartRate(): void {

    if (player.isCrouching) this.heartRateChange = heartRateConfig.downhillPhaseRemove

  }

  /** Kalkulacia heartrateu v shooting faze */
  private calculateShootingHeartRate(): void {

    if (this.isShootingPhase()) this.heartRateChange = heartRateConfig.shootingPhaseRemove

  }

  /**  Vypocet heartratebaru */
  private calculateSpeedBarHeartRate(): void {

    this.speedBarHeartRate = player.getSpeedPowerState() + heartRateConfig.speedBarCoef -
            player.getSpeedBarMaxValue()

  }

  /** Prisposobenie hodnoty k heartratebaru */
  private calculateRunningHeartRate() {

    this.calculateSpeedBarHeartRate()

    const { runningPhaseRemove, runningPhaseAdd } = heartRateConfig

    if (this.speedBarHeartRate < this.heartRate) {

      this.heartRateChange = this.speedBarHeartRate - this.heartRate <= runningPhaseRemove ?
        runningPhaseRemove :
        this.heartRate - this.speedBarHeartRate

    } else if (this.speedBarHeartRate > this.heartRate) {

      this.heartRateChange = this.speedBarHeartRate - this.heartRate >= runningPhaseAdd ?
        runningPhaseAdd :
        this.heartRate - this.speedBarHeartRate

    }

  }

  /**
   * Zmena hnodty tepu
   */
  private changeHeartRate(
    minHeartRate: number = heartRateConfig.minRate,
    maxHeartRate: number = heartRateConfig.maxRate
  ): void {

    this.heartRate += this.heartRateChange

    if (this.heartRate < minHeartRate) this.heartRate = minHeartRate
    if (this.heartRate > maxHeartRate) this.heartRate = maxHeartRate

    this.heartRateChange = 0

  }

  /** calculacia heartrateu, komplexna logika */
  private calculateHeartRate(): void {

    this.calculateRunningHeartRate()
    this.calculateSprintingHeartRate()
    this.calculateDownhillHeartRate()
    this.calculateShootingHeartRate()

    this.changeHeartRate()

  }

  /** Update metoda - tuto volame inde lebo sa to rata kazdy frame */
  public update(): void {

    if (this.gameNotStarted()) return
    if (this.oneSecPassed()) this.calculateHeartRate()
    heartRateState().heartRate = this.getHeartRate()

    this.setAudio()

  }

  /**
   * nastavime zvuky
   */
  private setAudio(): void {

    if (disciplinePhasesManager.oneOfPhaseIsActual([
      DisciplinePhases.running,
      DisciplinePhases.shootingIntro,
      DisciplinePhases.shootingOutro
    ])) {

      // buchanie srdca
      if (this.heartRate <= audioGameConfig.hearthRateRunningTreshold) {

        audioManager.stopAudioByName(AudioNames.heartbeat)

      } else {

        audioManager.play(AudioNames.heartbeat)
        audioManager.changeAudioVolume(AudioNames.heartbeat, 1)

      }

      // dychanie

      if (this.heartRate <= audioGameConfig.breathingRunningTreshold) {

        audioManager.stopAudioByName(AudioNames.heavyBreathing)

      } else {

        const breathingTreshold = audioGameConfig.breathingRunningTreshold
        const volume = 0.6 + ((this.heartRate - breathingTreshold) / 5 * 0.05)

        audioManager.play(AudioNames.heavyBreathing)
        audioManager.changeAudioVolume(AudioNames.heavyBreathing, volume)

      }

    }

    if (disciplinePhasesManager.actualPhase === DisciplinePhases.shooting) {

      audioManager.stopAudioByName(AudioNames.heavyBreathing)

      const { minRate, maxRate } = heartRateConfig
      const { min, max } = audioGameConfig.heartRateShootingVolume
      const heartRatePercent = (this.heartRate - minRate) / (maxRate - minRate)
      const volume = min + (heartRatePercent * (max - min))
      audioManager.play(AudioNames.heartbeat)
      audioManager.changeAudioVolume(AudioNames.heartbeat, volume)

    }

  }

}
