import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/**
 * Konfig pre kameru pre training
 */
export const trainingConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(-60, 10.75, -22),
      startLookAt: new THREE.Vector3(-78.346, 1.78, 9.92),
      endPosition: new THREE.Vector3(-40, 5.75, -15.25),
      duration: 5,
      notSkippableFrames: 20,
    },
    [CameraStates.intro2]: undefined,
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(100, 55, -10),
      startLookAt: new THREE.Vector3(-25, 1, 50),
      endPosition: new THREE.Vector3(101, 56, -11),
      duration: 5
    },
    [CameraStates.disciplineIntro]: undefined,
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: undefined,
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5

}
