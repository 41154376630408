import { CANNON } from '@powerplay/core-minigames'

/**
 * Konfig pre trackpointy
 */
export const trackpointEntityConfig = {
  trackpoints: [
    {
      id: 0,
      position: new CANNON.Vec3(-5.6058302158270825, 0.06827010498026026, -20.96081771963715)
    },
    {
      id: 1,
      position: new CANNON.Vec3(-5.6058302158270825, 0.06827010498026026, -18.96081771963715)
    },
    {
      id: 2,
      position: new CANNON.Vec3(-5.6058302158270825, 0.06827010498026026, -14.960817719637154)
    },
    {
      id: 3,
      position: new CANNON.Vec3(-5.568864368094782, 0.0735531674900784, -8.891839835523045)
    },
    {
      id: 4,
      position: new CANNON.Vec3(-4.576923789409769, 0.06270469207855739, -1.085359338528022)
    },
    {
      id: 5,
      position: new CANNON.Vec3(-2.891093098731184, 0.06284338178486165, 8.703915670832565)
    },
    {
      id: 6,
      position: new CANNON.Vec3(-1.4143319950200395, 0.06283911229906208, 17.19698816618412)
    },
    {
      id: 7,
      position: new CANNON.Vec3(-0.29073912777946564, 0.06273481297769762, 25.923413808654185)
    },
    {
      id: 8,
      position: new CANNON.Vec3(0.17687352192982791, 0.04903576819231431, 33.5922659852862)
    },
    {
      id: 9,
      position: new CANNON.Vec3(0.38676707951913103, -0.06057410132924873, 41.12973486686369)
    },
    {
      id: 10,
      position: new CANNON.Vec3(0.33220636433677597, -0.5138763208879282, 49.23434951985128)
    },
    {
      id: 11,
      position: new CANNON.Vec3(-0.4295212312724922, -0.9677780158246889, 55.86852432305759)
    },
    {
      id: 12,
      position: new CANNON.Vec3(-2.4365553924809067, -1.3284427510108088, 62.67077518799464)
    },
    {
      id: 13,
      position: new CANNON.Vec3(-5.880496321638703, -1.2475321650065438, 67.73172792242019)
    },
    {
      id: 14,
      position: new CANNON.Vec3(-9.552773204659829, -0.20593873819924796, 72.24626073317076)
    },
    {
      id: 15,
      position: new CANNON.Vec3(-12.695360828706054, 0.6843047362182262, 75.53784867141071)
    },
    {
      id: 16,
      position: new CANNON.Vec3(-15.264473662265566, 1.3944099664597502, 77.6299729895921)
    },
    {
      id: 17,
      position: new CANNON.Vec3(-19.33113150140183, 2.5847314665709744, 80.13975605351)
    },
    {
      id: 18,
      position: new CANNON.Vec3(-23.83575937163623, 3.8290679652208324, 82.17377055802542)
    },
    {
      id: 19,
      position: new CANNON.Vec3(-28.135510083480987, 5.070898667229545, 83.19865720721356)
    },
    {
      id: 20,
      position: new CANNON.Vec3(-33.83782501226915, 6.189270785736528, 83.21748707700274)
    },
    {
      id: 21,
      position: new CANNON.Vec3(-40.7178589364788, 7.015771895430194, 82.39121986767806)
    },
    {
      id: 22,
      position: new CANNON.Vec3(-49.73169255806091, 7.85530612042196, 80.4850322752826)
    },
    {
      id: 23,
      position: new CANNON.Vec3(-58.049072514877665, 9.059832797317956, 79.49664854976115)
    },
    {
      id: 24,
      position: new CANNON.Vec3(-64.35184612097308, 10.598222758219567, 81.34958939023161)
    },
    {
      id: 25,
      position: new CANNON.Vec3(-68.22926878460164, 12.273209925942044, 84.80858409751562)
    },
    {
      id: 26,
      position: new CANNON.Vec3(-70.49902456238908, 13.291557915118805, 88.53033681236089)
    },
    {
      id: 27,
      position: new CANNON.Vec3(-71.29537011494466, 13.907126813756424, 92.85403762073182)
    },
    {
      id: 28,
      position: new CANNON.Vec3(-70.38703102815394, 13.97522274682061, 96.83061696817535)
    },
    {
      id: 29,
      position: new CANNON.Vec3(-68.22363134883189, 13.402133537107904, 100.10767248496364)
    },
    {
      id: 30,
      position: new CANNON.Vec3(-65.13640023174281, 12.253188656587316, 103.41160212420739)
    },
    {
      id: 31,
      position: new CANNON.Vec3(-57.99476605137963, 10.775698642787152, 106.55212432365879)
    },
    {
      id: 32,
      position: new CANNON.Vec3(-52.35844952736265, 10.566937612704908, 106.16221278865349)
    },
    {
      id: 33,
      position: new CANNON.Vec3(-45.785323584904546, 10.721859429169971, 105.13647084407107)
    },
    {
      id: 34,
      position: new CANNON.Vec3(-41.761666781591096, 10.786150799328144, 104.66449897111998)
    },
    {
      id: 35,
      position: new CANNON.Vec3(-36.63453882203077, 10.74423006257128, 104.11142372934464)
    },
    {
      id: 36,
      position: new CANNON.Vec3(-32.098425546582575, 10.5138093286557, 104.28430922463535)
    },
    {
      id: 37,
      position: new CANNON.Vec3(-27.245903870616907, 10.450761402627197, 105.00270652394268)
    },
    {
      id: 38,
      position: new CANNON.Vec3(-22.95421093681303, 10.46364800027705, 105.95631128804762)
    },
    {
      id: 39,
      position: new CANNON.Vec3(-17.48611459835594, 10.56600796231361, 107.611781137966)
    },
    {
      id: 40,
      position: new CANNON.Vec3(-13.1235773510001, 10.250506269876187, 109.40804187122659)
    },
    {
      id: 41,
      position: new CANNON.Vec3(-8.265203858217818, 9.837213313342492, 112.0068067311494)
    },
    {
      id: 42,
      position: new CANNON.Vec3(-3.3763624212081957, 9.90941509171697, 115.2096258505292)
    },
    {
      id: 43,
      position: new CANNON.Vec3(0.04970687993602674, 9.768903809174677, 118.10432133684063)
    },
    {
      id: 44,
      position: new CANNON.Vec3(3.637018825902742, 9.455310110985938, 121.19922824916436)
    },
    {
      id: 45,
      position: new CANNON.Vec3(6.414401164310839, 9.312896778922909, 123.59398507171703)
    },
    {
      id: 46,
      position: new CANNON.Vec3(10.408921946240325, 8.936176104407696, 127.01691630688744)
    },
    {
      id: 47,
      position: new CANNON.Vec3(14.488633254337168, 8.419311489292571, 130.13561244566682)
    },
    {
      id: 48,
      position: new CANNON.Vec3(18.959610191096044, 7.970013575629852, 133.16227740375984)
    },
    {
      id: 49,
      position: new CANNON.Vec3(23.4332354279558, 7.683652809951883, 135.6321523900894)
    },
    {
      id: 50,
      position: new CANNON.Vec3(28.357136699049324, 7.878485920317618, 137.37482954113287)
    },
    {
      id: 51,
      position: new CANNON.Vec3(31.76951226835845, 8.356725379417075, 138.18383803354456)
    },
    {
      id: 52,
      position: new CANNON.Vec3(35.68643444727919, 9.209549192209113, 138.45756981681706)
    },
    {
      id: 53,
      position: new CANNON.Vec3(38.71227686421121, 9.85209914750246, 138.25309876804883)
    },
    {
      id: 54,
      position: new CANNON.Vec3(46.5181479623527, 11.340069641327933, 134.61733524413816)
    },
    {
      id: 55,
      position: new CANNON.Vec3(50.03254909195589, 11.716841908707384, 132.17279641680344)
    },
    {
      id: 56,
      position: new CANNON.Vec3(53.83381510128341, 11.844058543120656, 128.91576093022405)
    },
    {
      id: 57,
      position: new CANNON.Vec3(58.63491505442854, 11.732676887540027, 124.7685209286247)
    },
    {
      id: 58,
      position: new CANNON.Vec3(63.70124592409265, 11.328436031839647, 118.15323857733193)
    },
    {
      id: 59,
      position: new CANNON.Vec3(65.45352029619195, 10.60828544492998, 109.60033620787529)
    },
    {
      id: 60,
      position: new CANNON.Vec3(56.35842409012456, 10.181021818235099, 92.1574081525172)
    },
    {
      id: 61,
      position: new CANNON.Vec3(45.38198746635385, 8.857219514564097, 86.94348270493268)
    },
    {
      id: 62,
      position: new CANNON.Vec3(37.8218819934344, 6.9602344589353065, 86.11608143369702)
    },
    {
      id: 63,
      position: new CANNON.Vec3(9.09061429315206, 2.0263418188675244, 78.77246486818113)
    },
    {
      id: 64,
      position: new CANNON.Vec3(3.5316758033742843, 1.5675055081535618, 72.82800064188605)
    },
    {
      id: 65,
      position: new CANNON.Vec3(0.4543048965008518, 2.332869348811303, 68.60597114952564)
    },
    {
      id: 66,
      position: new CANNON.Vec3(-9.574830940409171, 1.7851319526949994, 60.07097919200171)
    },
    {
      id: 67,
      position: new CANNON.Vec3(-11.815084172489174, 0.7830427049647739, 58.31857439236376)
    },
    {
      id: 68,
      position: new CANNON.Vec3(-14.351491060865056, 0.2702461297766831, 54.030641605480774)
    },
    {
      id: 69,
      position: new CANNON.Vec3(-16.404202509946906, 0.2629612113738659, 50.12071180222685)
    },
    {
      id: 70,
      position: new CANNON.Vec3(-18.102248004603, 0.21122852237447204, 45.76694173099851)
    },
    {
      id: 71,
      position: new CANNON.Vec3(-19.018229008437356, 0.15726803135703732, 41.76540610194238)
    },
    {
      id: 72,
      position: new CANNON.Vec3(-19.78312140333452, 0.11114316211122172, 37.52495168884213)
    },
    {
      id: 73,
      position: new CANNON.Vec3(-20, 0.9891, 29.377777777778082)
    },
    {
      id: 74,
      position: new CANNON.Vec3(-20, 0.9891, 23.75555555555618)
    },
    {
      id: 75,
      position: new CANNON.Vec3(-20, 0.9891, 19.411111111111982)
    },
    {
      id: 76,
      position: new CANNON.Vec3(-20, 0.9891, 12.511111111112374)
    },
    {
      id: 77,
      position: new CANNON.Vec3(-20, 1, 9.800000000000114)
    },
    {
      id: 78,
      position: new CANNON.Vec3(-20, 1, -1.3999999999992496)
    },
    {
      id: 79,
      position: new CANNON.Vec3(-20, 1, -8.59999999999884)
    },
    {
      id: 80,
      position: new CANNON.Vec3(-20, 1, -14.39999999999851)
    },
    {
      id: 81,
      position: new CANNON.Vec3(-20.056628772360042, 0.06270822559429517, -18.539886233312885)
    },
    {
      id: 82,
      position: new CANNON.Vec3(-20.513371577961827, 0.06266306053906873, -26.453863986938263)
    },
    {
      id: 83,
      position: new CANNON.Vec3(-20.36805698433041, 0.06257151680843548, -30.828787798152526)
    },
    {
      id: 84,
      position: new CANNON.Vec3(-19.710376540364077, 0.06249782539396448, -36.23038435520839)
    },
    {
      id: 85,
      position: new CANNON.Vec3(-18.78301610566212, 0.062453006198640526, -41.56630595403941)
    },
    {
      id: 86,
      position: new CANNON.Vec3(-16.235776155204277, 0.06241190949228609, -46.39290612835735)
    },
    {
      id: 87,
      position: new CANNON.Vec3(-11.78551204612232, 0.0622636578561952, -50.4778949906881)
    },
    {
      id: 88,
      position: new CANNON.Vec3(-6.007993491599423, 0.062155746436093284, -52.857758906336485)
    },
    {
      id: 89,
      position: new CANNON.Vec3(0.6887284065078034, 0.062128030332902984, -53.33078317652942)
    },
    {
      id: 90,
      position: new CANNON.Vec3(6.395717699291527, 0.06202368218321225, -50.17444275187562)
    },
    {
      id: 91,
      position: new CANNON.Vec3(10.698453683409845, 0.06185816949441686, -46.10335955570768)
    },
    {
      id: 92,
      position: new CANNON.Vec3(12.65494545408072, 0.06178890694470168, -41.43512356278834)
    },
    {
      id: 93,
      position: new CANNON.Vec3(13.552344518275861, 0.06182073909956215, -37.21562623148101)
    },
    {
      id: 94,
      position: new CANNON.Vec3(13.85100640930104, 0.061925011781912065, -33.46726391198211)
    },
    {
      id: 95,
      position: new CANNON.Vec3(13.918296409047509, 0.06209298147745129, -28.40509597918794)
    },
    {
      id: 96,
      position: new CANNON.Vec3(13.84227372536479, 0.06232983475378545, -22.689016571707985)
    },
    {
      id: 97,
      position: new CANNON.Vec3(13.84227372536479, 0.06232983475378545, -18.689016571707985)
    },
    {
      id: 98,
      position: new CANNON.Vec3(13.84227372536479, 0.06232983475378545, -14.689016571707985)
    }
  ]

}
