<template>
  <div>
    <countdown-shiny
      :active="active1"
      type="text-3"
      :duration="countdownState.duration"
      style="top: -25%;"
    />
    <countdown-shiny
      :active="active2"
      type="text-2"
      :duration="countdownState.duration"
      style="top: -25%;"
    />
    <countdown-shiny
      :active="active3"
      type="text-1"
      :duration="countdownState.duration"
      style="top: -25%;"
    />
    <countdown-shiny
      :active="active4"
      type="text-start"
      :duration="countdownState.duration"
      style="top: -25%;"
      @callback="reset"
    />
  </div>
</template>

<script lang="ts">
import { CountdownShiny } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import {
  countdownState,
  type CountdownState
} from '@/stores'

export default defineComponent({
  name: 'CountdownComponentDicipline',
  components: {
    CountdownShiny
  },
  data() {

    return {
      active1: false,
      active2: false,
      active3: false,
      active4: false,
      countdownState: countdownState(),
    }

  },
  watch: {
    countdownState: {
      handler(value: CountdownState) {

        console.log('countdown state.... ', value.text)
        if (value.text === '3') {

          console.log('active 1')
          this.active1 = true

        } else if (value.text === '2') {

          console.log('active 2')
          this.active2 = true

        } else if (value.text === '1') {

          console.log('active 3')
          this.active3 = true

        } else if (value.text === 'START') {

          console.log('active 4')
          this.active4 = true

        }

      },
      deep: true
    }

  },
  methods: {
    reset() {

      console.warn('reset')
      this.active1 = false
      this.active2 = false
      this.active3 = false
      this.active4 = false
      countdownState().isEnabled = false
      countdownState().text = ''

    }
  }
})
</script>

<style scoped lang="less">

    .countdown-container {
        z-index: 1;
    }

</style>
