import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'

export const audioSprites: AudioObject[] = [
  {
    files: [
      AudioNames.noLoop
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    version: 1,
    category: AudioCategories.sprite,
    sprite: {
      'BI_rifle_equip': [
        0,
        261.81405895691614
      ],
      'BI_rifle_pull_action': [
        2000,
        505.215419501134
      ],
      'BI_rifle_shot': [
        4000,
        1153.2426303854875
      ],
      'BI_skiing_break': [
        7000,
        2997.1201814058954
      ],
      'BI_target_hit': [
        11000,
        964.2857142857135
      ],
      'countdown_beep_long': [
        13000,
        870.6122448979592
      ],
      'countdown_beep_short': [
        15000,
        755.850340136055
      ],
      'crowd_sad_var02': [
        17000,
        2431.269841269842
      ],
      'crowd_sad_var03': [
        21000,
        2382.2902494331047
      ],
      'crowd_yeah_var02': [
        25000,
        2909.0476190476197
      ],
      'crowd_yeah_var03': [
        29000,
        2146.167800453515
      ]
    }
  },
  {
    files: [
      AudioNames.loop
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: true,
    version: 2,
    category: AudioCategories.sprite,
    sprite: {
      'audience_bad': [
        0,
        7679.047619047619
      ],
      'audience_sad': [
        9000,
        10008.004535147393
      ],
      'audience_yay': [
        21000,
        8295.01133786848
      ]
    }
  },
  {
    files: [
      AudioNames.bells
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    version: 1,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    sprite: {
      'bells_var02': [
        0,
        7600
      ],
      'bells_var021': [
        9000,
        6433.333333333334
      ]
    }
  },
  {
    files: [
      AudioNames.targetMissSprite
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 0.5,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    version: 1,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    sprite: {
      'BI_target_miss_var02': [
        0,
        208.9795918367347
      ],
      'BI_target_miss_var022': [
        2000,
        313.4693877551023
      ],
      'BI_target_miss_var023': [
        4000,
        211.58730158730154
      ]
    }
  },
  {
    files: [
      AudioNames.commentatorSounds
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    ignoreModes: [3, 14, 9, 10],
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    version: 1,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    group: AudioGroups.commentators,
    subset: true,
    sprite: {
      'before_split_time': [
        0,
        3927.0748299319725
      ],
      'before_split_time2': [
        5000,
        5517.460317460317
      ],
      'finish_1': [
        12000,
        5915.986394557823
      ],
      'finish_2': [
        19000,
        4930
      ],
      'finish_3': [
        25000,
        3045.0113378684805
      ],
      'finish_4': [
        30000,
        5132.517006802722
      ],
      'split_times_2-3': [
        37000,
        2886.8934240362805
      ],
      'split_times_2-3_2': [
        41000,
        5117.9818594104345
      ],
      'split_times_2-3_3': [
        48000,
        5072.766439909295
      ],
      'split_times_4': [
        55000,
        4625.782312925167
      ],
      'split_times_4_2': [
        61000,
        4124.44444444445
      ],
      'split_times_lead': [
        67000,
        3904.4217687074793
      ],
      'split_times_lead2': [
        72000,
        5170.748299319726
      ],
      'split_times_lead3': [
        79000,
        4514.965986394557
      ]
    }
  }
]