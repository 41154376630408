import { audioHelper } from '@/app/audioHelper/AudioHelper'
import {
  cameraCloserConfig,
  triggerEntityConfig
} from '@/app/config'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import type { RunningPhase } from '@/app/phases/RunningPhase/RunningPhase'
import { SplitTimeManager } from '@/app/SplitTimeManager/SplitTimeManager'
import {
  AudienceChangeVolumeTypes,
  AudienceChangeVolumePlaceTypes,
  DisciplinePhases,
  TutorialEventType,
  AudioNames
} from '@/app/types'
import {
  audioManager,
  CANNON,
  type CannonNamedBody,
  type CollisionEvent,
  game,
  modes,
  gsap
} from '@powerplay/core-minigames'
import { player } from '../player'

/** Nazvy triggerov */
export enum TriggerNames {
  triggerIn = 'TriggerIn',
  triggerOut = 'TriggerOut',
  triggerFinish = 'TriggerFinish',
  triggerCameraCloser = 'TriggerCameraCloser',
  triggerPreSplit = 'TriggerPreSplit',
  triggerSplit = 'TriggerSplit',
  triggerStartOfFirstHill = 'triggerStartOfFirstHill',
  triggerStartOfFirstDownhill = 'triggerStartOfFirstDownhill',
  triggerEndOfFirstDownhill = 'triggerEndOfFirstDownhill',
  triggerUnderTheBridge = 'triggerUnderTheBridge',
  audienceDecreaseVolumeStart = 'audienceDecreaseVolumeStart',
  audienceDecreaseVolumeStop = 'audienceDecreaseVolumeStop',
  audienceIncreaseVolumeStart = 'audienceIncreaseVolumeStart',
  audienceIncreaseVolumeStop = 'audienceIncreaseVolumeStop',
  hypeStart = 'hypeStart_0',
  hypeStop = 'hypeStop_0',
  hypeStart1 = 'hypeStart_1',
  hypeStop1 = 'hypeStop_1',
  hypeStart2 = 'hypeStart_2',
  hypeStop2 = 'hypeStop_2'
}

/** Typ trigera */
export interface trigger {
  name: TriggerNames,
  position: {
    x: number,
    y: number,
    z: number
  },
  rotation?: {
    x: number,
    y: number,
    z: number
  }
}

/**
 * Klassa na triggery pre prechod medzi modmi hry
 */
export class Triggers {

  /** vyska triggrov */
  private TRIGGER_HEIGHT = 5

  /** sirka triggrov */
  private TRIGGER_WIDTH = 5

  /** hrubka triggeru */
  private TRIGGER_DEPTH = 0.1

  /** Array vsetkych bodies */
  private triggerBodies: CannonNamedBody[] = []

  /** Ci bola aktivovana kamera priblizenia */
  public cameraCloserTriggerActivated = false

  /** split time manager */
  public splitTimeManager = new SplitTimeManager()

  /** HACK pre zmenu hlasitosti */
  private volChangeTween !: gsap.core.Tween

  /** Metoda na tvorbu triggerov */
  public create(): void {

    this.createTriggers()

  }

  /**
   * Vytvorenie triggerov
   */
  private createTriggers(): void {

    console.log('Vytvaram triggery...')
    triggerEntityConfig.triggers.forEach((trigger) => {

      this.createTrigger(trigger)

    })
    triggerEntityConfig.splitTriggers.forEach((trigger) => {

      this.createTrigger(trigger)

    })
    triggerEntityConfig.preSplitTriggers.forEach((trigger) => {

      this.createTrigger(trigger)

    })
    triggerEntityConfig.audioTriggers.forEach((trigger) => {

      this.createTrigger(trigger)

    })
    console.log('Triggery su hotove...')

  }

  /**
   * Vytvorenie triggera ako fyzickeho objektu
   * @param trigger - Trigger prvok miesta a mena
   */
  private createTrigger(trigger: trigger): void {

    const physicsBody = new CANNON.Body({
      mass: 0,
      shape: new CANNON.Box(new CANNON.Vec3(
        this.TRIGGER_WIDTH,
        this.TRIGGER_HEIGHT,
        this.TRIGGER_DEPTH
      )),
      material: new CANNON.Material('Trigger'),
      isTrigger: true,
      collisionFilterGroup: 2,
      collisionFilterMask: 2
    }) as CannonNamedBody

    physicsBody.name = trigger.name
    physicsBody.position.x = trigger.position.x
    physicsBody.position.y = trigger.position.y
    physicsBody.position.z = trigger.position.z

    if (trigger.rotation) {

      physicsBody.quaternion.setFromEuler(
        trigger.rotation.x,
        trigger.rotation.y,
        trigger.rotation.z
      )

    }

    game.physics.addBody(physicsBody)

    physicsBody.addEventListener('collide', (e: CollisionEvent) => {

      if (e.body.name === 'collisionPlayerBody') {

        console.log('trigger ', physicsBody.name)

        if (physicsBody.name === TriggerNames.triggerIn) {

          const runningPhase = disciplinePhasesManager
            .getDisciplinePhaseManager(DisciplinePhases.running) as RunningPhase
          runningPhase.pausePhase()
          disciplinePhasesManager.startDisciplinePhase(DisciplinePhases.shootingIntro)

          this.splitTimeManager.endActualSplit()

        } else if (physicsBody.name === TriggerNames.triggerOut) {

          /*
           * const runningPhase = disciplinePhasesManager
           *     .getDisciplinePhaseManager(DisciplinePhases.running) as RunningPhase
           * runningPhase.unPausePhase()
           */

          this.splitTimeManager.endActualSplit()

        } else if (physicsBody.name === TriggerNames.triggerCameraCloser) {

          if (!this.cameraCloserTriggerActivated) {

            player.changeCameraSettings(
              cameraCloserConfig.cameraConfig.idealOffset,
              cameraCloserConfig.cameraConfig.idealLookAt,
              cameraCloserConfig.cameraConfig.coefSize,
              cameraCloserConfig.cameraConfig.changeLerp
            )

            this.cameraCloserTriggerActivated = true
            player.setIsUnderTheBridge(true)

          } else {

            player.setGameCameraSettings(cameraCloserConfig.cameraConfig.changeLerp)
            this.cameraCloserTriggerActivated = false
            player.setIsUnderTheBridge(false)

          }

        } else if (physicsBody.name === TriggerNames.triggerUnderTheBridge) {

          // kontrola triggera triggerCameraCloser

          if (this.cameraCloserTriggerActivated) return

          player.changeCameraSettings(
            cameraCloserConfig.cameraConfig.idealOffset,
            cameraCloserConfig.cameraConfig.idealLookAt,
            cameraCloserConfig.cameraConfig.coefSize,
            cameraCloserConfig.cameraConfig.changeLerp
          )

          this.cameraCloserTriggerActivated = true
          player.setIsUnderTheBridge(true)

        } else if (physicsBody.name === TriggerNames.triggerSplit) {

          this.splitTimeManager.endActualSplit()
          setTimeout(() => {

            this.removePhysicsBodyById(physicsBody.id)

          }, 0)

        } else if (physicsBody.name === TriggerNames.triggerStartOfFirstHill) {

          tutorialFlow.eventActionTrigger(TutorialEventType.startOfFirstHill)
          setTimeout(() => {

            this.removePhysicsBodyById(physicsBody.id)

          }, 0)

        } else if (physicsBody.name === TriggerNames.triggerStartOfFirstDownhill) {

          tutorialFlow.eventActionTrigger(TutorialEventType.startOfFirstDownhill)
          setTimeout(() => {

            this.removePhysicsBodyById(physicsBody.id)

          }, 0)

        } else if (physicsBody.name === TriggerNames.triggerEndOfFirstDownhill) {

          tutorialFlow.eventActionTrigger(TutorialEventType.endOfFirstDownhill)
          setTimeout(() => {

            this.removePhysicsBodyById(physicsBody.id)

          }, 0)

        } else if (physicsBody.name === TriggerNames.triggerPreSplit) {

          if (modes.isTutorial()) return

          this.splitTimeManager.setActualSplitTimeData()
          setTimeout(() => {

            this.removePhysicsBodyById(physicsBody.id)

          }, 0)

        } else if (physicsBody.name === TriggerNames.audienceDecreaseVolumeStart) {

          audioHelper.setChangeAudienceVolumeAfterTrigger(
            AudienceChangeVolumeTypes.decrease,
            AudienceChangeVolumePlaceTypes.start
          )

        } else if (physicsBody.name === TriggerNames.audienceDecreaseVolumeStop) {

          audioHelper.setChangeAudienceVolumeAfterTrigger(
            AudienceChangeVolumeTypes.decrease,
            AudienceChangeVolumePlaceTypes.stop
          )

        } else if (physicsBody.name === TriggerNames.audienceIncreaseVolumeStart) {

          audioHelper.setChangeAudienceVolumeAfterTrigger(
            AudienceChangeVolumeTypes.increase,
            AudienceChangeVolumePlaceTypes.start
          )

        } else if (physicsBody.name === TriggerNames.audienceIncreaseVolumeStop) {

          audioHelper.setChangeAudienceVolumeAfterTrigger(
            AudienceChangeVolumeTypes.increase,
            AudienceChangeVolumePlaceTypes.stop
          )

        } else if (physicsBody.name === TriggerNames.hypeStart) {

          this.audioHypePlay()

          setTimeout(() => {

            this.removePhysicsBodyById(physicsBody.id)

          }, 0)

        } else if (physicsBody.name === TriggerNames.hypeStop) {

          this.audioHypeStop()

          setTimeout(() => {

            this.removePhysicsBodyById(physicsBody.id)

          }, 0)

        } else if (physicsBody.name === TriggerNames.hypeStart1) {

          this.audioHypePlay()

          setTimeout(() => {

            this.removePhysicsBodyById(physicsBody.id)

          }, 0)

        } else if (physicsBody.name === TriggerNames.hypeStop1) {

          this.audioHypeStop()

          setTimeout(() => {

            this.removePhysicsBodyById(physicsBody.id)

          }, 0)

        } else if (physicsBody.name === TriggerNames.hypeStart2) {

          this.audioHypePlay()

          setTimeout(() => {

            this.removePhysicsBodyById(physicsBody.id)

          }, 0)

        } else if (physicsBody.name === TriggerNames.hypeStop2) {

          this.audioHypeStop()

          setTimeout(() => {

            this.removePhysicsBodyById(physicsBody.id)

          }, 0)

        } else {

          const runningPhase = disciplinePhasesManager
            .getDisciplinePhaseManager(DisciplinePhases.running) as RunningPhase
          runningPhase.finishPhase()
          this.splitTimeManager.endActualSplit()

        }

      }

    })

    /** Add body to array */
    this.triggerBodies.push(physicsBody)

  }

  /**
   * zahrame audio pre trigger hype
   */
  private audioHypePlay(): void {

    audioManager.play(AudioNames.audienceHype)
    audioManager.play(AudioNames.audienceBells)

    if (this.volChangeTween) this.volChangeTween.kill()

    /** docasny hack */
    audioHelper.hypeVolume.vol = 0
    this.volChangeTween = gsap.to(audioHelper.hypeVolume, {
      vol: 0.75,
      duration: 0.5,
      ease: 'none'
    })

  }

  /**
   * zrusime audio pre trigger hype stop
   */
  private audioHypeStop(): void {

    if (this.volChangeTween) this.volChangeTween.kill()

    /** docasny hack */
    audioHelper.hypeVolume.vol = 0.75
    this.volChangeTween = gsap.to(audioHelper.hypeVolume, {
      vol: 0,
      duration: 1,
      onComplete: () => {

        audioManager.stopAudioByName(AudioNames.audienceHype)
        audioManager.stopAudioByName(AudioNames.audienceBells)

      },
      ease: 'none'
    })

  }

  /**
   * Vymazanie fyzickeho body
   * @param triggerName - Meno triggeru
   */
  public removePhysicsBodyByName(triggerName: TriggerNames): void {

    this.triggerBodies.forEach(body => {

      if (body.name === triggerName) {

        game.physics.remove(body)

      }

    })

  }

  /**
   * Vymazanie fyzickeho body
   * @param triggerName - Meno triggeru
   */
  public removePhysicsBodyById(id: number): void {

    this.triggerBodies.forEach(body => {

      if (body.id === id) {

        game.physics.remove(body)

      }

    })

  }

}

export const triggers = new Triggers()
