import {
  AudioNames,
  type DisciplinePhaseManager,
  Tasks,
  TutorialEventType,
  TutorialObjectiveIds
} from '../../types'
import {
  audioManager,
  gsap,
  cameraManager,
  CameraStates,
  game,
  MobileDetector,
  modes,
  THREE,
  timeManager
} from '@powerplay/core-minigames'
import { inputsManager } from '@/app/InputsManager'
import { shootingPhaseConfig } from '@/app/config/shootingPhaseConfig'
import { shootingDirectionManager } from '@/app/shootingDirectionManager'
import { player } from '@/app/entities/player'
import { shootingTargetsManager } from '@/app/shootingTargetsManager'
import { rifleManager } from '../../entities/rifle/RifleManager'
import { trainingTasks } from '@/app/modes/training/TrainingTasks'
import { ObjectWrapper } from '@/app/entities/objectWrapper/ObjectWrapper'
import { gameConfig } from '@/app/config'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { tutorialObjectives } from '@/app/modes/tutorial/TutorialObjectives'
import { ShootingTraining } from '@/app/modes/training'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import {
  actionButtonState,
  bulletsTargetsBoxState,
  gamePhaseState,
  inputsState,
  movementButtonsState,
  movementState,
  splitTimeState,
  startPhaseState,
  tutorialState
} from '@/stores'

/**
 * Trieda fazy pre zjazd
 */
export class ShootingPhaseManager implements DisciplinePhaseManager {

  /** ci sme stalcili akciu */
  private isFreezedShooting = false

  /** ktory pokus momentalne mame */
  private actualAttempt = 1

  /** kolko sme netrafili */
  private missedShots = 0

  /** kolko sme trafili */
  private hitTargets = 0

  /** pocitadlo aktualneho frame pocas zamrazenia po akcii */
  private freezedFrameCount = 0

  /** THREEjs skupina pre diery od nabojov */
  private bulletHolesGroup = new THREE.Group()

  /** wrapper pre pusku a kameru */
  public wrapper = new ObjectWrapper()

  /** o kolko posuvame terce kazdy frame */
  private targetFlipStep = new THREE.Vector3()

  /** shooting training manager */
  private shootingTraining !: ShootingTraining

  /** kedy povazujeme terc preklopeny */
  private TARGET_FLIP_TARGET = new THREE.Vector3(0, Math.PI, 0)

  /** kamera up vektor */
  private CAMERA_UP_VECTOR = new THREE.Vector3(0, 1, 0)

  private bulletHoles: THREE.Mesh[] = []

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Vratenie poctu missnutych striel
   * @returns Pocet missnutych striel
   */
  public getMissedShots(): number {

    return this.missedShots

  }

  /**
   * kolko sme trafili
   * @returns kolko sme trafili
   */
  public getHitTargets(): number {

    return this.hitTargets

  }

  /**
   * Vratenie poctu vystrelenych striel
   * @returns Pocet vystrelenych striel
   */
  public getShotShots(): number {

    return this.actualAttempt - 1

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    splitTimeState().splitTimeData = []
    actionButtonState().firedWeapon = false
    inputsState().isVisible = true

    console.warn('shooting phase started')

    if (modes.isTutorial()) {

      this.isFreezedShooting = true
      gsap.to({}, {
        duration: 0.5,
        onComplete: () => {

          this.isFreezedShooting = false

        }
      })

    }
    tutorialFlow.eventActionTrigger(TutorialEventType.shootingStart)
    timeManager.changeTimeSpeed()

    this.shootingTraining = new ShootingTraining(this)

    this.showMobileButtons()
    shootingDirectionManager.setMouseStep()

    shootingTargetsManager.createTargets()
    shootingTargetsManager.setVisibilityAllCoverPlanes(false)
    shootingDirectionManager.createTargetPoint()
    rifleManager.prepareShooting()
    if (modes.isTrainingMode()) rifleManager.hideRifle()

    this.setCameraWrapper()
    this.setCameraRendering()
    this.wrapper.lookAt(shootingDirectionManager.targetPoint.position)

    this.targetFlipStep.set(
      this.TARGET_FLIP_TARGET.x / shootingPhaseConfig.targetFlipFrames,
      this.TARGET_FLIP_TARGET.y / shootingPhaseConfig.targetFlipFrames,
      this.TARGET_FLIP_TARGET.z / shootingPhaseConfig.targetFlipFrames
    )

    bulletsTargetsBoxState().$patch({
      visible: true,
      bullets: shootingPhaseConfig.attemptCount,
      targets: new Array(shootingPhaseConfig.attemptCount).fill(false)
    })

    tutorialState().showArrowAim = true

    // diery od nabojov
    game.scene.add(this.bulletHolesGroup)

    // forcneme zobrazenie joysticku a povolime akciu
    inputsState().isForceJoystick = true
    actionButtonState().disabled = false
    gamePhaseState().showSmallActionButtons = false
    startPhaseState().showBar = false

    audioHelper.stopAllMovementAudio()

    if (!modes.isTrainingMode()) audioManager.play(AudioNames.rifleEquip)

  }

  /**
   * dame kameru a pusku do wrappera
   */
  public setCameraWrapper(): void {

    const camera = cameraManager.getMainCamera()
    camera.up.set(0, 1, 0)

    cameraManager.setState(CameraStates.static)

    camera.position.set(0, 0, 0)
    camera.lookAt(new THREE.Vector3(0, 0, 1))

    this.wrapper.set(
      shootingPhaseConfig.cameraWrapperPosition[
        shootingTargetsManager.getActualShootingType()
      ],
      camera,
      rifleManager.rifleObjectGroup
    )

  }

  /**
   * nastavime vykreslovanie kamery
   */
  public setCameraRendering(): void {

    player.changeCameraRenderSettings(
      shootingPhaseConfig.camera.near,
      shootingPhaseConfig.camera.far,
      shootingPhaseConfig.camera.fov
    )

    cameraManager.getMainCamera().up = this.CAMERA_UP_VECTOR

  }

  /**
   * resetneme kameru na konci
   */
  private resetCameraRendering(): void {

    cameraManager.setState(CameraStates.discipline)

    player.changeCameraRenderSettings()

  }

  /**
   * zobrazime mobilne buttony
   */
  private showMobileButtons(): void {

    if (!MobileDetector.isMobile()) return

    inputsState().disabled = false
    actionButtonState().$patch({
      showJoystick: true,
      isStart: false,
      isShooting: true
    })
    actionButtonState().disabled = false

  }

  /**
   * Vytvorenie diery od naboja
   */
  private createBulletHole(): void {

    if (!shootingPhaseConfig.showBulletHoles) return

    const bulletHole = game.getMesh('ImpactMark').clone()

    bulletHole.position.copy(shootingDirectionManager.targetPoint.position)
    bulletHole.position.x += 0.005
    bulletHole.rotation.z = Math.PI / 2
    bulletHole.updateMatrix()
    this.bulletHoles.push(bulletHole)
    this.bulletHolesGroup.add(bulletHole)

  }

  /**
   * metoda na odstranenie dier po vystrele
   * TODO Zatial nemazeme
   */
  private removeBulletHoles(): void {

    for (let i = 0; this.bulletHolesGroup.children.length; i++) {

      game.scene.remove(this.bulletHolesGroup.children[i])

    }

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    if (this.shootingTraining.trainingModeLogic()) return

    // schovame kurzor
    if (!modes.isTutorial() && document.body.style.cursor !== 'none') {

      document.body.style.cursor = 'none'

    }

    this.wrapper.lookAt(shootingDirectionManager.targetPoint.position)

    shootingDirectionManager.update()

    if (MobileDetector.isMobile()) {

      this.shootAction(actionButtonState().firedWeapon)
      actionButtonState().firedWeapon = false

    } else {

      this.shootAction(inputsManager.actionPressed === 1)

    }

    if (this.isFreezedShooting) {

      this.freezedFrameCount++
      this.afterShootFreeze()

    }

    rifleManager.update()

    audioHelper.update()

  }

  /**
   * po freeznuti
   */
  private afterShootFreeze = (): void => {

    if (this.freezedFrameCount % shootingPhaseConfig.delayFrames) return

    this.isFreezedShooting = false
    actionButtonState().disabled = false

    this.shootingTraining.afterShootFreeze(this.actualAttempt)
    if (modes.isTrainingMode()) return

    if (this.actualAttempt > shootingPhaseConfig.attemptCount) this.finishPhase()

  }

  /**
   * co robime na vystrele
   * @param isAction - ci sme vykonali akciu
   */
  private shootAction(isAction: boolean): void {

    if (!isAction || this.isFreezedShooting) return

    audioManager.stopAudioByName(AudioNames.rifleShoot)
    audioManager.play(AudioNames.rifleShoot)

    // nastavime freeznutia po strelbe
    this.isFreezedShooting = true
    actionButtonState().disabled = true

    if (shootingTargetsManager.findIntersects()) {

      this.hitTargets++

      tutorialObjectives.passObjective(TutorialObjectiveIds.shooting as string)

      audioHelper.setShotSound(AudioNames.targetHit)

      const audioName = (this.actualAttempt % 2) ?
        AudioNames.targetHitCrowd :
        AudioNames.targetHitCrowd2

      audioManager.stopAudioByName(audioName)
      audioManager.play(audioName)

    } else {

      audioHelper.setShotSound(AudioNames.targetMiss)

      const audioName = (this.actualAttempt % 2) ?
        AudioNames.targetMissCrowd :
        AudioNames.targetMissCrowd2

      audioManager.stopAudioByName(audioName)
      audioManager.play(audioName)

      this.missedAction()

    }

    // vynulujeme pocitadlo na zamrazenie
    this.freezedFrameCount = 0

    // zapneme pohyb
    rifleManager.startRifleJump()

    let bulletsAmount = shootingPhaseConfig.attemptCount - this.actualAttempt
    if (modes.isTrainingMode() && this.actualAttempt >= 6) {

      bulletsAmount = shootingPhaseConfig.attemptCountTraining - this.actualAttempt

    }
    bulletsTargetsBoxState().bullets = bulletsAmount

    this.actualAttempt++
    trainingTasks.countTaskValue(Tasks.missedShots)

    // naboj debug
    this.createBulletHole()

    this.shootingTraining.shootAction(this.actualAttempt)

  }

  /**
   * Akcia pri missnuti terca
   */
  private missedAction(): void {

    this.missedShots++
    if (modes.isTrainingMode()) return
    timeManager.addPenalty()

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * Vyhodime zo sceny objekty ktore sme vytvorili pocas fazy
   */
  private removeShootingObjects(): void {

    shootingTargetsManager.removeObjectsFromScene()
    shootingDirectionManager.removeObjectsFromScene()

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    console.warn('shooting phase ended')

    if (modes.isTutorial()) {

      if (!tutorialFlow.endAfterShooting) this.resetTutorialShooting()
      tutorialFlow.eventActionTrigger(TutorialEventType.shootingEnd)
      return

    }

    // vratime naspat kurzor mysy
    document.body.style.cursor = 'auto'
    // vycistime scenu
    this.removeShootingObjects()
    player.setGameCameraSettings(1)
    this.resetCameraRendering()
    this.wrapper.destroy()
    // schovame pusku
    rifleManager.hideRifle()
    actionButtonState().isShooting = false
    inputsState().disabled = true
    movementState().onlyHorizontal = true
    movementButtonsState().isActive = true

    // this.resetProperties() - toto by sme mali davat az pri realnom resete
    shootingTargetsManager.resetTargetsRotation()

    timeManager.changeTimeSpeed(gameConfig.timeSpeedRunning)
    this.callbackEnd()

    // zrusime forcnute zobrazenie joysticku
    inputsState().isForceJoystick = false

    bulletsTargetsBoxState().visible = false
    gamePhaseState().showSmallActionButtons = true

  }

  /**
   * reset pre tutorial
   */
  public resetTutorialShooting(): void {

    shootingTargetsManager.setNextTargetsType()
    shootingTargetsManager.resetTargets()
    shootingDirectionManager.reset()
    shootingDirectionManager.createTargetPoint()
    rifleManager.prepareShooting()
    this.wrapper.destroy()
    this.setCameraWrapper()
    this.setCameraRendering()
    this.wrapper.lookAt(shootingDirectionManager.targetPoint.position)
    this.resetProperties()

    bulletsTargetsBoxState().bullets = shootingPhaseConfig.attemptCount
    this.isFreezedShooting = true
    gsap.to({}, {
      duration: 0.5,
      onComplete: () => {

        this.isFreezedShooting = false

      }
    })

  }

  /**
   * Test reset na full phase
   */
  public resetPhase(): void {

    shootingTargetsManager.resetTargets()
    shootingDirectionManager.reset()
    this.resetProperties()
    this.bulletHoles.forEach(bulletHole => {

      this.bulletHolesGroup.remove(bulletHole)

    })
    this.bulletHoles = []
    rifleManager.reset()

  }

  /**
   * resetneme property fazy pre moznost dalsieho pokusu
   */
  private resetProperties(): void {

    this.actualAttempt = 1
    this.missedShots = 0
    this.hitTargets = 0

  }

  /**
   * predcasne ukoncenie shooting trainingu
   */
  public killShootingTraining(): void {

    this.shootingTraining?.killTraining()

  }

}
