import { defineStore } from 'pinia'

export interface HeartRateState {
  showRate: boolean,
  heartRate: number
}

const initialState = (): HeartRateState => ({
  showRate: false,
  heartRate: 90
})

export const heartRateState = defineStore('heartRateState', {
  state: initialState,
})
