<template>
  <mobile-button
    :type="type"
    :is-scaled="false"
    :disabled="actionButtonState.disabled"
    @mousedown="performAction"
    @mouseup="endAction"
    @touchstart="performAction"
    @touchend="endAction"
  />
</template>

<script lang="ts">
import { MobileButton } from '@powerplay/core-minigames-ui'
import { inputsManager } from '@powerplay/core-minigames'
import { defineComponent } from 'vue'
import {
  actionButtonState,
  tuckState
} from '@/stores'

export default defineComponent({
  name: 'ActionButton',
  components: {
    MobileButton
  },
  data() {

    return {
      actionButtonState: actionButtonState(),
    }

  },
  computed: {
    type() {

      if (actionButtonState().isStart) return 'start-biatlon'
      if (actionButtonState().isShooting) return 'shoot'
      return ''

    }
  },
  methods: {
    performAction() {

      if (actionButtonState().disabled) return

      if (this.type === 'tuck') {

        tuckState().isTuck = true

      }

      if (this.type === 'start-biatlon') {

        actionButtonState().touchStart = true

      }

      if (this.type === 'shoot') {

        actionButtonState().firedWeapon = true

      }

    },
    endAction() {

      if (this.type === 'tuck') {

        tuckState().isTuck = false

      }

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    }
  }
})
</script>

<style>

</style>
