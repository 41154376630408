import { defineStore } from 'pinia'

export interface TimerState {
  penaltySeconds: number,
  timeWithPenalty: string,
  time: string,
}

const initialState = (): TimerState => ({
  penaltySeconds: 0,
  timeWithPenalty: '0.0',
  time: '0.0'
})

export const timeState = defineStore('timeState', {
  state: initialState,
})
