/** Nazvy zvukov */
export enum AudioNames {

  noLoop = 'noLoop',
  loop = 'loop',
  bells = 'bells',
  targetMissSprite = 'target_miss',
  commentatorSounds = 'commentators',

  countdownBeepLong = 'countdown_beep_long',
  countdownBeepShort = 'countdown_beep_short',

  audienceBad = 'audience_bad',
  audienceHype = 'audience_hype',
  audienceNoise = 'audience_noise_var_02',
  audienceNoise1 = 'audience_noise_var_011',
  audienceNoise2 = 'audience_noise_var_012',
  audienceSad = 'audience_sad',
  audienceYay = 'audience_yay',
  audienceBells = 'bells_var02',
  audienceBells1 = 'bells_var021',

  heartbeat = 'BI_heartbeat',
  heavyBreathing = 'BI_heavy_breathing',

  skiingBreak = 'BI_skiing_break',
  skiingGlide = 'BI_skiing_glide',
  runFastpace = 'BI_skiing_run_fastpace_all',
  runSlowpace = 'BI_skiing_run_slowpace_all',

  rifleEquip = 'BI_rifle_equip',
  riflePullAction = 'BI_rifle_pull_action',
  rifleShoot = 'BI_rifle_shot',
  targetHitCrowd = 'crowd_yeah_var02',
  targetHitCrowd2 = 'crowd_yeah_var03',
  targetMissCrowd = 'crowd_sad_var02',
  targetMissCrowd2 = 'crowd_sad_var03',
  targetHit = 'BI_target_hit',
  targetMiss = 'BI_target_miss_var02',
  targetMiss1 = 'BI_target_miss_var022',
  targetMiss2 = 'BI_target_miss_var023',

  commentBeforeFinish = 'before_finish',
  commentBeforeFinish2 = 'before_finish2',
  commentBeforeSplitTime = 'before_split_time',
  commentBeforeSplitTime2 = 'before_split_time2',
  commentFinalResults = 'final_results',
  commentFinalResults2 = 'final_results2',
  commentFinish1 = 'finish_1',
  commentFinish2 = 'finish_2',
  commentFinish3 = 'finish_3',
  commentFinish4 = 'finish_4',
  commentIntro = 'intro',
  commentIntro2 = 'intro2',
  commentShootingEnd1Miss = 'shooting_end_1_miss',
  commentShootingEnd1Miss2 = 'shooting_end_1_miss2',
  commentShootingEnd2Misses = 'shooting_end_2_misses',
  commentShootingEnd2Misses2 = 'shooting_end_2_misses2',
  commentShootingEnd2Misses3 = 'shooting_end_2_misses3',
  commentShootingEndClean = 'shooting_end_clean',
  commentShootingEndClean2 = 'shooting_end_clean2',
  commentShootingIntro = 'shooting_intro',
  commentShootingIntro2 = 'shooting_intro2',
  commentSplitTimes23 = 'split_times_2-3',
  commentSplitTimes232 = 'split_times_2-3_2',
  commentSplitTimes233 = 'split_times_2-3_3',
  commentSplitTimes4 = 'split_times_4',
  commentSplitTimes42 = 'split_times_4_2',
  commentSplitTimesLead = 'split_times_lead',
  commentSplitTimesLead2 = 'split_times_lead2',
  commentSplitTimesLead3 = 'split_times_lead3'

}

/** Kategorie zvukov */
export enum AudioCategories {

  misc = 'misc',
  audience = 'audience',
  countdown = 'countdown',
  character = 'character',
  running = 'running',
  shooting = 'shooting',
  commentators = 'commentators',
  sprite = 'sprites'

}

/** skupiny zvukov */
export enum AudioGroups {

  commentators = 'commentators'

}

/** pre spustanie audia po vystrele */
export interface AfterShootAudio {

  wasShot: boolean,
  count: number,
  audioName: string,
  playedHit: boolean

}

/** Typy pre zmenu hlasitosti divakov */
export enum AudienceChangeVolumePlaceTypes {

  start = 'start',
  stop = 'stop'

}

/** Typy pre zmenu hlasitosti divakov */
export enum AudienceChangeVolumeTypes {

  increase = 'increase',
  decrease = 'decrease'

}

/** Typy sektorov pre hlasitost */
export enum AudienceVolumeSectorTypes {

  full = 'full',
  graduallyDecrease = 'graduallyDecrease',
  graduallyIncrease = 'graduallyIncrease',
  min = 'min'

}
