import {
  type DisciplinePhaseManager,
  DisciplinePhases
} from '../../types'
import {
  CANNON,
  MobileDetector
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import { player } from '@/app/entities/player'
import type { ShootingIntroPhase } from '../ShootingIntroPhase/ShootingIntroPhase'
import { gameConfig } from '@/app/config'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import {
  actionButtonState,
  gamePhaseState,
  inputsState,
  movementButtonsState
} from '@/stores'

/**
 * Trieda fazy pre zjazd
 */
export class RunningPhase implements DisciplinePhaseManager {

  /** Ci je falsed */
  public paused = false

  public debugShootingOnce = false

  /** callback na zavolanie po skonceni fazy */
  public callbackEnd: () => unknown

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Ziskanie stavu pauzed
   * @returns ci je pauzed
   */
  public getPaused(): boolean {

    return this.paused

  }

  /**
   * Metoda na pauznutie hry
   */
  public pausePhase(): void {

    this.paused = true
    movementButtonsState().isActive = false
    const shootingIntro = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.shootingIntro) as ShootingIntroPhase
    shootingIntro.setInitialPosition(player.getPosition())
    // player.physicsBody.type = CANNON.BODY_TYPES.KINEMATIC
    console.log('Game phase pause')

  }

  /**
   * Metoda na spustenie pauznutej hry
   */
  public unPausePhase(): void {

    movementButtonsState().isActive = true
    player.makeInstantLerp()
    player.physicsBody.type = CANNON.BODY_TYPES.DYNAMIC
    this.paused = false

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    console.warn('game phase started')
    gamePhaseState().showBar = true
    movementButtonsState().isActive = true
    this.showMobileButtons()

    if (gameConfig.startWithShooting && !this.debugShootingOnce) {

      this.debugShootingOnce = true

      const runningPhase = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.running) as RunningPhase
      runningPhase.pausePhase()
      disciplinePhasesManager.startDisciplinePhase(DisciplinePhases.shootingIntro)

    }

  }

  public showMobileButtons(): void {

    if (!MobileDetector.isMobile()) return

    actionButtonState().$patch({
      showJoystick: true,
      isStart: false
    })
    inputsState().disabled = false

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    audioHelper.update()

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    console.warn('game phase ended')
    this.callbackEnd()

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

}
