import {
  game,
  errorManager,
  THREE
} from '@powerplay/core-minigames'
import { shootingPhaseConfig } from '../../config/shootingPhaseConfig'
import {
  ModelsNames,
  type ProjectileFlyingData,
  type RifleAfterShootingData,
  type RifleLoadingData
} from '../../types'
/**
 * manazer pusky
 */
export class RifleManager {

  /** objekt grupy pusky */
  public rifleObjectGroup!: THREE.Object3D

  /** objekt pusky */
  public rifleObject!: THREE.Object3D

  /** objekt nabojnice */
  public projectileObject!: THREE.Object3D

  /** ukladame si data o pohybe pusky po vystrele */
  private afterShootMove: RifleAfterShootingData = {
    isRotateXup: false,
    isRotateXdown: false,
    rotateXupStep: 0,
    rotateXdownStep: 0,

    isMoveZback: false,
    isMoveZforward: false,
    moveZbackStep: 0,
    moveZforwardStep: 0
  }

  /** ukladame si data o pohybe pusky pri nabijani */
  private rifleLoadingData: RifleLoadingData = {
    isRotationToTarget: false,
    isWaiting: false,
    isRotationFromTarget: false,
    rotateToTargetStep: 0,
    rotateFromTargetStep: 0
  }

  /** ukladame si data o pohybe nabojnice */
  private projectileFlying: ProjectileFlyingData = {
    isActive: false,
    step: new THREE.Vector3()
  }

  /** zkladna pozicia rifle grupy */
  private BASE_RIFLE_GROUP_POSITION = new THREE.Vector3(0, -0.025, 0.1)

  /** zakladna rotacia rigle grupy */
  private BASE_RIFLE_GROUP_ROTATION = new THREE.Vector3(0, Math.PI / 2, 0)

  /** zakladna pozicia pusky */
  private BASE_RIFLE_OBJECT_POSITION = new THREE.Vector3(0, 0.025, 0)

  /** kolko framov posuvame projektyl */
  private projectileFrames = 0

  /**
   * pociatocne nastavenie objektu pusky
   */
  public setRifleObject(): void {

    this.rifleObjectGroup = game.getObject3D(ModelsNames.rifle)
    this.rifleObject = game.getObject3D('BiathlonistRifle')
    this.projectileObject = game.getObject3D('Projectile')

    if (!this.rifleObjectGroup || !this.rifleObject) {

      throw new Error(errorManager.showBox('Rifle si missing'))

    }

    if (!this.projectileObject) {

      throw new Error(errorManager.showBox('projectile si missing'))

    }

    this.rifleObjectGroup.position.set(0, -100, 0)
    this.rifleObjectGroup.rotation.order = 'YZX'

    this.projectileObject.matrixAutoUpdate = true
    this.projectileObject.position.set(0, -100, 0)

  }

  /**
   * nastavime pusku pred strelbou
   */
  public prepareShooting(): void {

    this.setAfterShootingSteps()

    rifleManager.rifleObjectGroup.translateZ(shootingPhaseConfig.rifleTranslationFromCamera)

    this.rifleObjectGroup.position.copy(this.BASE_RIFLE_GROUP_POSITION)

    this.rifleObject.position.copy(this.BASE_RIFLE_OBJECT_POSITION)
    this.rifleObject.updateMatrix()

    this.rifleObjectGroup.rotation.set(
      this.BASE_RIFLE_GROUP_ROTATION.x,
      this.BASE_RIFLE_GROUP_ROTATION.y,
      this.BASE_RIFLE_GROUP_ROTATION.z
    )

  }

  /**
   * Reset aftershootmove
   */
  public resetAfterShootMove(): void {

    this.afterShootMove = {
      isRotateXup: false,
      isRotateXdown: false,
      rotateXupStep: 0,
      rotateXdownStep: 0,

      isMoveZback: false,
      isMoveZforward: false,
      moveZbackStep: 0,
      moveZforwardStep: 0
    }

  }

  /**
   * nastavime kroky zmeny rotacie/posunu po vystreleni
   */
  private setAfterShootingSteps(): void {

    const jumpConfig = shootingPhaseConfig.rifleJump
    const loadConfig = shootingPhaseConfig.rifleLoad
    const jumpRotDiff = jumpConfig.rotationX - this.BASE_RIFLE_GROUP_ROTATION.x
    const jumpMovDiff = jumpConfig.positionZ - this.BASE_RIFLE_GROUP_POSITION.z
    const loadDiff = loadConfig.rifleRotationX - this.BASE_RIFLE_GROUP_ROTATION.x
    const ammoDiff = new THREE.Vector3(
      loadConfig.bulletTargetPosition.x - loadConfig.bulletStartPosition.x,
      loadConfig.bulletTargetPosition.y - loadConfig.bulletStartPosition.y,
      loadConfig.bulletTargetPosition.z - loadConfig.bulletStartPosition.z
    )

    this.afterShootMove.rotateXupStep = jumpRotDiff / jumpConfig.framesRotationXup
    this.afterShootMove.rotateXdownStep = jumpRotDiff / jumpConfig.framesRotationXdown * -1
    this.afterShootMove.moveZbackStep = jumpMovDiff / jumpConfig.framesMovementZback
    this.afterShootMove.moveZforwardStep = jumpMovDiff / jumpConfig.framesMovementZforward * -1

    this.rifleLoadingData.rotateToTargetStep = loadDiff / loadConfig.framesRotateToTarget
    this.rifleLoadingData.rotateFromTargetStep = loadDiff / loadConfig.framesRotatingBack * -1

    this.projectileFlying.step = new THREE.Vector3(
      ammoDiff.x / loadConfig.framesBulletMovemet,
      ammoDiff.y / loadConfig.framesBulletMovemet,
      ammoDiff.z / loadConfig.framesBulletMovemet
    )

  }

  /**
   * zacneme skok pusky
   */
  public startRifleJump(): void {

    this.afterShootMove.isRotateXup = true
    this.afterShootMove.isMoveZback = true

  }

  /**
   * update kazdy frame pocas streleckej fazy
   */
  public update(): void {

    this.afterShootingRifleMovemement()

  }

  /**
   * zavola fcie ktore pohybuju pusku po vystrele
   */
  private afterShootingRifleMovemement(): void {

    this.afterShootingRotateDown()
    this.afterShootingMoveForward()

    this.afterShootingRotateUp()
    this.afterShootingMoveBack()

    this.rifleLoadFromTarget()
    this.rifleLoadWait()
    this.rifleLoadToTarget()

    this.projectileFlyAway()

  }

  /**
   * zacneme animaciu nabijania
   */
  private startLoadingRifle(): void {

    this.rifleLoadingData.isRotationToTarget = true

  }

  /**
   * posuvame pusku od nabijania k normalu
   */
  private rifleLoadFromTarget(): void {

    if (!this.rifleLoadingData.isRotationFromTarget) return

    this.rifleObjectGroup.rotation.x += this.rifleLoadingData.rotateFromTargetStep

    if (this.rifleObjectGroup.rotation.x > 0) return

    this.rifleObjectGroup.rotation.x = 0
    this.rifleLoadingData.isRotationFromTarget = false

  }

  /**
   * cakame s puskou (nabitie)
   */
  private rifleLoadWait(): void {

    if (!this.rifleLoadingData.isWaiting) return

    this.projectileFrames++

    if (this.projectileFrames < shootingPhaseConfig.rifleLoad.framesWaiting) return

    this.rifleLoadingData.isWaiting = false
    this.rifleLoadingData.isRotationFromTarget = true

  }

  /**
   * posuvame pusku k cielu - nabijanie
   */
  private rifleLoadToTarget(): void {

    if (!this.rifleLoadingData.isRotationToTarget) return

    this.rifleObjectGroup.rotation.x += this.rifleLoadingData.rotateToTargetStep

    const targetRotation = shootingPhaseConfig.rifleLoad.rifleRotationX

    if (this.rifleObjectGroup.rotation.x < targetRotation) return

    this.rifleObjectGroup.rotation.x = targetRotation
    this.rifleLoadingData.isRotationToTarget = false

    this.rifleLoadingData.isWaiting = true
    this.projectileFlying.isActive = true

  }

  /**
   * posuvame nabojnicu prec
   */
  private projectileFlyAway(): void {

    if (!this.projectileFlying.isActive) return

    if (this.projectileFrames === 0) {

      this.projectileObject.position.copy(shootingPhaseConfig.rifleLoad.bulletStartPosition)
      this.projectileFrames++
      return

    }

    this.projectileFrames++

    this.projectileObject.position.x += this.projectileFlying.step.x
    this.projectileObject.position.y += this.projectileFlying.step.y
    this.projectileObject.position.z += this.projectileFlying.step.z

    if (this.projectileFrames < shootingPhaseConfig.rifleLoad.framesBulletMovemet) return

    this.projectileFlying.isActive = false
    this.projectileObject.position.y = -100
    this.projectileFrames = 0

  }

  /**
   * rotujeme pusku hore po vystrele
   */
  private afterShootingRotateUp(): void {

    if (!this.afterShootMove.isRotateXup) return

    this.rifleObjectGroup.rotation.z += this.afterShootMove.rotateXupStep

    if (this.rifleObjectGroup.rotation.z > shootingPhaseConfig.rifleJump.rotationX) return

    this.rifleObjectGroup.rotation.z = shootingPhaseConfig.rifleJump.rotationX
    this.afterShootMove.isRotateXup = false
    this.afterShootMove.isRotateXdown = true

  }

  /**
   * rotujeme pusku dole po vystrele
   */
  private afterShootingRotateDown(): void {

    if (!this.afterShootMove.isRotateXdown) return

    this.rifleObjectGroup.rotation.z += this.afterShootMove.rotateXdownStep

    if (this.rifleObjectGroup.rotation.z < this.BASE_RIFLE_GROUP_ROTATION.x) return

    this.rifleObjectGroup.rotation.z = this.BASE_RIFLE_GROUP_ROTATION.x
    this.afterShootMove.isRotateXdown = false

    this.startLoadingRifle()

  }

  /**
   * posuvame pusku dozadu po vystrele
   */
  private afterShootingMoveBack(): void {

    if (!this.afterShootMove.isMoveZback) return

    this.rifleObjectGroup.position.z += this.afterShootMove.moveZbackStep

    if (this.rifleObjectGroup.position.z > shootingPhaseConfig.rifleJump.positionZ) return

    this.rifleObjectGroup.position.z = shootingPhaseConfig.rifleJump.positionZ
    this.afterShootMove.isMoveZback = false
    this.afterShootMove.isMoveZforward = true

  }

  /**
   * posuvame pusku dozadu po vystrele
   */
  private afterShootingMoveForward(): void {

    if (!this.afterShootMove.isMoveZforward) return

    this.rifleObjectGroup.position.z += this.afterShootMove.moveZforwardStep

    if (this.rifleObjectGroup.position.z < this.BASE_RIFLE_GROUP_POSITION.z) return

    this.rifleObjectGroup.position.z = this.BASE_RIFLE_GROUP_POSITION.z
    this.afterShootMove.isMoveZforward = false

  }

  /**
   * schovame pusku
   */
  public hideRifle(): void {

    this.rifleObjectGroup.position.set(0, -100, 0)
    // ak odpojime v buducnosti nabojnicu z rifleObjectGroup bude treba skryvat aj nabojnicu

  }

  /**
   * vratime pusku pred kameru
   */
  public showRifle(): void {

    this.rifleObjectGroup.position.copy(this.BASE_RIFLE_GROUP_POSITION)

  }

  /**
   * Reset
   */
  public reset(): void {

    this.setAfterShootingSteps()

  }

}

export const rifleManager = new RifleManager()
