import { player } from '../entities/player'
import {
  CANNON,
  gsap
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '../phases/DisciplinePhasesManager'
import { DisciplinePhases } from '../types'
import { stateManager } from '../StateManager'

export class SpeedManager {

  /** tween na zobrazenie rychlosti */
  private speedmeterTween!: gsap.core.Tween

  /** momentalna rychlost v m/s */
  public actualSpeed!: number

  /** ci treba aktualizovat momentalnu rychlost */
  private actualSpeedNeedsUpdate = false

  /** ci mame zobrazit momentalnu rychlost */
  private actualSpeedShouldBeDisplayed = false

  /**
   * nastavime aktualnu rychlost
   * @param velocity - Vektor rychlosti
   */
  public setActualSpeed(velocity: CANNON.Vec3): void {

    this.actualSpeed = Math.sqrt((velocity.x ** 2) + (velocity.y ** 2) + (velocity.z ** 2))
    this.actualSpeedNeedsUpdate = false

  }

  /**
   * zobrazime aktualnu rychlost
   */
  public showActualSpeed(): void {

    this.actualSpeedNeedsUpdate = true
    this.actualSpeedShouldBeDisplayed = true

  }

  /**
   * Update funkcia
   */
  public update(): void {

    this.setActualSpeed(player.physicsBody.velocity)
    if (this.actualSpeedShouldBeDisplayed && !this.actualSpeedNeedsUpdate) {

      this.displayActualSpeed()

    }

  }

  /**
   * Zobrazenie rychlosti
   */
  private displayActualSpeed(): void {

    if (disciplinePhasesManager.getActualPhase() !== DisciplinePhases.running ||
        !this.actualSpeed) return
    if (this.speedmeterTween) this.speedmeterTween.progress(1)

    const speedData = {
      visible: true,
      speed: this.actualSpeed.toFixed(2)
    }
    stateManager.updateSpeedData(speedData)

    this.speedmeterTween = gsap.to({}, {
      duration: 2,
      onComplete: () => {

        stateManager.hideSpeedVisibility()

      }
    })
    this.actualSpeedShouldBeDisplayed = false

  }

  /**
   * Reset
   */
  public reset(): void {

    this.actualSpeedNeedsUpdate = false
    this.actualSpeedShouldBeDisplayed = false

  }

}

export const speedManager = new SpeedManager()
