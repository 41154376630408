import {
  SectionNames,
  AudioNames,
  DisciplinePhases
} from '../../types'
import { tutorialDefaultStates } from './TutorialDefaultState'
import {
  audioManager,
  MobileDetector,
  tutorialManager,
  TutorialMessageColors
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  blurState,
  tutorialState
} from '@/stores'
import {
  gameSettingsState,
  loadingState,
  tutorialCoreState
} from '@powerplay/core-minigames-ui'

/**
 * Informacie pre UI priapdne kontrolne prvky na zmenu UI roznych taskov
 */
export class TutorialUIChange {

  /** Meno prave beziacej sekcie */
  private actualSectionName: SectionNames | undefined

  /** Samotny stav ktory chceme vo roznych krokoch tutorialu */
  private uiState = {
    [SectionNames.handCheck]: () => {

      blurState().isActive = true
      this.setMobile(true)
      // dame prec loading
      loadingState().showLoading = false

    },
    [SectionNames.startSection]: () => {

      this.resetTypeWrite()
      this.setMobile(false)
      this.setMessage(true, 'tutorialText2-1')
      this.setAnne(true)

      // dame prec loading (pre web)
      loadingState().showLoading = false
      audioManager.play(AudioNames.commentIntro)

    },
    [SectionNames.startSectionSecond]: () => {

      this.resetTypeWrite()
      const mobile = MobileDetector.isMobile()
      tutorialState().showButtonStart = true
      this.setMessage(true, 'tutorialText2-2', undefined, undefined, !mobile)
      let isLeft = gameSettingsState().isLeft
      if (!mobile) isLeft = false

      this.setAnne(true, isLeft)

    },
    [SectionNames.startSectionThird]: () => {

      this.resetTypeWrite()
      // zmenime store - podla storeu sa zmeny UI
      this.setMessage(false, '')
      this.setAnne(false)

    },
    [SectionNames.startSectionFourth]: () => {

      this.resetTypeWrite()
      const mobile = MobileDetector.isMobile()
      const text = mobile ? 'tutorialText2-4' : 'tutorialText2-3'
      this.setMessage(true, text)
      this.setAnne(false)

    },
    [SectionNames.startSectionFifth]: () => {

      this.resetTypeWrite()
      this.setMessage(false, '')
      this.setAnne(false)

    },
    [SectionNames.startSectionSixth]: () => {

      this.resetTypeWrite()
      this.setMessage(true, 'tutorialText2-5', undefined, 'sprint')
      let isLeft = gameSettingsState().isLeft
      if (!MobileDetector.isMobile()) isLeft = false

      this.setAnne(true, isLeft)

    },
    [SectionNames.startSectionSeventh]: () => {

      this.resetTypeWrite()
      this.setMessage(false, '')
      this.setAnne(false)

    },
    [SectionNames.startSectionEight]: () => {

      this.resetTypeWrite()
      this.setMessage(false, '')
      this.setAnne(false)

    },
    [SectionNames.startSectionNinth]: () => {

      this.resetTypeWrite()
      this.setMessage(true, 'tutorialText2-7', undefined, 'downhillSetting')
      let isLeft = gameSettingsState().isLeft
      if (!MobileDetector.isMobile()) isLeft = false

      this.setAnne(true, isLeft)

    },
    [SectionNames.startSectionTenth]: () => {

      this.resetTypeWrite()
      this.setMessage(false, '')
      this.setAnne(false)

    },
    [SectionNames.startSectionEleventh]: () => {

      this.resetTypeWrite()
      this.setMessage(true, 'tutorialText2-9')
      this.setAnne(true, false)

    },
    [SectionNames.startSectionTwelfth]: () => {

      this.resetTypeWrite()
      this.setMessage(true, 'tutorialText2-10')
      this.setAnne(true, false)

    },
    [SectionNames.startSectionThirteenth]: () => {

      this.resetTypeWrite()
      this.setMessage(false, '')
      this.setAnne(false)

    },
    [SectionNames.startSectionFourteenth]: () => {

      this.resetTypeWrite()
      tutorialState().showArrowAim = false
      this.setMessage(false, '')
      this.setAnne(false)

    }
  }

  /** Referencia na vue */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private vm: any

  /**
   * Nastavenie Vue referencie
   * @param vm - vue referencia
   */
  public registerVM(vm: unknown): void {

    this.vm = vm

  }

  /**
   * Zena UI podla aktualnej sekcie
   * @param sectionName - Meno aktualnej sekcie
   */
  private changeUi(sectionName: SectionNames): void {

    this.uiState[sectionName]()

  }

  /**
   * Resetnutie typewrite
   */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(true)
    tutorialCoreState().typeWriter = true

  }

  /**
   * Inicializovanie
   */
  public init(): void {

    // Ak bude treba.

  }

  /**
   * Hlavna logika ui zmeny
   */
  private tutorialUILogic(): void {

    const sectionName = tutorialManager.getActualSectionName() as SectionNames
    if (sectionName && sectionName !== this.actualSectionName) {

      this.changeUi(sectionName)
      this.actualSectionName = sectionName

    }

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    tutorialDefaultStates.update()
    this.tutorialUILogic()

  }

  /**
   * Nastavenie Anny
   * @param showAnne - Ci sa ma zobrazit
   * @param isLeft - Ci sa ma umiestnit vpravo
   * @returns this
   */
  public setAnne(
    showAnne: boolean,
    isLeft = gameSettingsState().isLeft
  ): this {

    tutorialState().anne = {
      showAnne,
      isRight: isLeft
    }

    return this

  }

  /**
   * Nastavenie hlasky pre tutorial
   * @param showMessage - ci sa ma zobrazit hlaska
   * @param message - text
   * @param color - farba
   * @param yellowTextSpecial - specialny text klikni na XYZ
   * @returns this
   */
  public setMessage(
    showMessage: boolean,
    message = '',
    color = TutorialMessageColors.blank,
    yellowTextSpecial = '',
    offset = false
  ): this {

    let yellowText = this.vm.$i18n.t('clickToContinueTemp')
    if (MobileDetector.isMobile()) yellowText = this.vm.$i18n.t('tapToContinue')
    if (yellowTextSpecial) {

      yellowText = this.vm.$i18n.t('tapToButton').replace(
        '[BUTTON]',
        this.vm.$i18n.t(yellowTextSpecial)
      )

    }

    tutorialState().tutorialMessage = {
      showMessage,
      message,
      color,
      yellowText,
      offset,
      yellowTextSpecial: yellowTextSpecial !== ''
    }

    if (disciplinePhasesManager.getActualPhase() !== DisciplinePhases.shooting) {

      return this

    }

    if (showMessage) {

      document.body.style.cursor = 'auto'

    } else {

      document.body.style.cursor = 'none'

    }
    return this

  }

  /**
   * Nastavenie mobilnej uvodnej obrazovky
   * @param show - Ci ma byt zobrazena
   * @returns this
   */
  public setMobile(show: boolean): this {

    tutorialState().mobile = show
    return this

  }

}

export const tutorialUIChange = new TutorialUIChange()
