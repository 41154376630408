<template>
  <div class="info-key-positioner">
    <tutorial-keyboard-keys
      v-if="showSideArrows"
      type="arrow-keys"
      :text="$t('webInfoLeft') + ' / ' + $t('webInfoRight')"
      :glow="true"
      is-scaled
      class="keyboard-side-keys"
    >
      <section
        class="tutorial-keyboard-keys-wrapper"
      >
        <tutorial-keyboard-key
          type="arrow"
          rotation="left"
          letter="a"
          glow
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="right"
          letter="d"
          glow
        />
      </section>
    </tutorial-keyboard-keys>
    <arrow-animation
      v-if="showVerticalArrows"
      position="bottom"
      style="position: absolute; width: 200px; height: 276px; right: 2%; bottom: 12%; transform-origin: 100% 100%;"
    />
    <tutorial-keyboard-keys
      v-if="showVerticalArrows"
      type="arrow-keys"
      :text="$t('webInfoTempo')"
      :glow="true"
      is-scaled
      class="keyboard-vertical-keys"
    >
      <section
        class="tutorial-keyboard-keys-wrapper"
      >
        <tutorial-keyboard-key
          type="arrow"
          rotation="top"
          letter="w"
          glow
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="down"
          letter="s"
          glow
        />
      </section>
    </tutorial-keyboard-keys>
    <arrow-animation
      v-if="showSpace"
      position="bottom"
      style="position: absolute; right: 2%; width: 310px; height: 146px; bottom: 12%; transform-origin: 100% 100%;"
    />
    <tutorial-keyboard-keys
      v-if="showSpace"
      type="space"
      :text="$t('webInfoStart')"
      :glow="true"
      class="keyboard-space yellow-glow"
      is-scaled
    >
      <tutorial-keyboard-key
        type="space"
        glow
      />
    </tutorial-keyboard-keys>
    <tutorial-keyboard-keys
      v-if="showShootingInfo"
      :text="$t('webInfoAim')"
      class="keyboard-side-keys"
      is-scaled
    >
      <div class="mouse-move" />
    </tutorial-keyboard-keys>
    <arrow-animation
      v-if="showShootingInfo"
      position="bottom"
      style="position: absolute; width: 114px; height: 186px; right: 2%; bottom: 12%; transform-origin: 100% 100%;"
    />
    <tutorial-keyboard-keys
      v-if="showShootingInfo"
      :text="$t('webInfoShoot')"
      class="keyboard-space"
      is-scaled
    >
      <div class="mouse-click" />
    </tutorial-keyboard-keys>
  </div>
</template>

<script lang="ts">
import {
  ArrowAnimation,
  TutorialKeyboardKeys,
  TutorialKeyboardKey
} from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import {
  bulletsTargetsBoxState,
  tutorialState
} from '@/stores'

export default defineComponent({
  name: 'TutorialInfoKeys',
  components: {
    ArrowAnimation,
    TutorialKeyboardKeys,
    TutorialKeyboardKey
  },
  computed: {
    showSpace() {

      return tutorialState().showButtonStart

    },
    showSideArrows() {

      return tutorialState().buttons.showMovementButtons

    },
    showVerticalArrows() {

      return tutorialState().buttons.showMovementButtons

    },
    showShootingInfo() {

      return bulletsTargetsBoxState().visible && tutorialState().showArrowAim

    }
  }
})
</script>

<style lang="less" scoped>
    .positioner {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 25%;

        .keyboard-side-keys {
            transform-origin: 0 100%;
            position: absolute;
            left: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                height: 100px;
            }
        }
        .keyboard-vertical-keys {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                width: 100px;
            }
            .tutorial-keyboard-header {
                width: 140px;
            }
        }

        .keyboard-space {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;
        }
    }
</style>
