import { shootingPhaseConfig } from '@/app/config/shootingPhaseConfig'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { ShootingPhaseManager } from '@/app/phases/ShootingPhase/ShootingPhase'
import {
  trainingManager,
  modes
} from '@powerplay/core-minigames'
import {
  DisciplinePhases,
  Tasks
} from '../../types'
import { trainingState } from '@/stores'

/**
 * Trieda pre treningove ulohy
 */
export class TrainingTasks {

  /** Mnozstvo taskov */
  private NUMBER_OF_TASKS = 3

  /** Cas pre prvych 5 striel */
  public timeInitialShots = -1

  /** Cas pre druhych 5 striel */
  public timeSecondaryShots = -1

  /**
   * Inicializovanie treningu
   */
  public initTraining(): void {

    trainingManager.setNumberOfTasks(this.NUMBER_OF_TASKS)

  }

  /**
   * Hlavna metoda ktoru volame pri pouziti trainingovych Taskov
   * @param task - Typu Tasks, typ ulohy ktorej hodnoty sa zadavaju
   * @param valueToCount - hodnota ktora sa zadava
   */
  public countTaskValue(task: Tasks /** , valueToCount: number */): void {

    if (!modes.isTrainingMode()) return

    if (task === Tasks.missedShots) this.countMissedShots()

  }

  /**
   * Metoda na vyhodnotenie strelby
   */
  private countMissedShots(): void {

    this.changeUI(Tasks.missedShots, this.getMissedShotsPercent())

  }

  /**
   * Hlavna privatna metoda, ktora riesi UI zmeny a trainingManager classu
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  public saveTaskValue(name: Tasks, value: number): void {

    if (!modes.isTrainingMode()) return

    value = Math.ceil(value * 10000) / 10000

    this.changeUI(name, value)
    trainingManager.setTaskValue(name, value)

  }

  /**
   * Zmena UI
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  private changeUI(name: Tasks, value: number) {

    if (!modes.isTrainingMode()) return
    console.log(`UI zmeny ${name} ma hodnotu ${value}`)
    const percent = Math.ceil(value * 100)
    const tpTask = Math.ceil(Math.round((trainingManager.getTpPerTask() * value * 10) + Number.EPSILON) / 10)
    console.log('tp task:', tpTask)

    if (tpTask !== undefined) {

      trainingState().editTask({
        text: `trainingTask2-${name}`,
        percent: String(percent),
        points: String(tpTask),
        showPoints: true
      })

    }

  }

  /**
   * Vratenie poctu percent za minute strely
   * @returns Percenta
   */
  private getMissedShotsPercent(): number {

    const shooting = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.shooting) as ShootingPhaseManager

    const missedShotsCount = shooting.getMissedShots()
    const shotShotsCount = shooting.getShotShots()

    let normalizedMissedShots =
        (shotShotsCount - missedShotsCount) / shootingPhaseConfig.attemptCountTraining
    if (normalizedMissedShots < 0) normalizedMissedShots = 0

    return normalizedMissedShots

  }

  /**
   * Logika pre cas
   * @returns - upravene task
   */
  private logicForTime(time: number): number {

    // ak este nezacalo, tak davame 0 rovno
    if (time === -1) return 0
    if (time <= 15) return 1

    const differenceSeconds = time - 15
    const returnVal = 100 - (differenceSeconds * 5)
    return returnVal < 0 ? 0.00 : returnVal / 100

  }

  /**
   * Ziskanie casu prveho kola
   * @returns - cas prveho kola
   */
  public getTimeFirstShots(): number {

    // logika
    return this.logicForTime(this.timeInitialShots)

  }

  /**
   * Ziskanie casu druheho kola
   * @returns - cas druheho kola
   */
  public getTimeSecondShots(): number {

    // logika
    return this.logicForTime(this.timeSecondaryShots)

  }

  /**
   * Ulozenie poslednych uloh
   */
  public saveLastTasksValues(): void {

    if (!modes.isTrainingMode()) return

    this.saveTaskValue(Tasks.missedShots, this.getMissedShotsPercent())
    this.saveTaskValue(Tasks.timeFirstShots, this.getTimeFirstShots())
    this.saveTaskValue(Tasks.timeSecondShots, this.getTimeSecondShots())

    console.log('FINAL DATA SENT')

  }

  /**
   * Reset
   */
  public reset(): void {

    trainingManager.resetAll()

  }

}

export const trainingTasks = new TrainingTasks()
