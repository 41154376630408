import { THREE } from '@powerplay/core-minigames'
import { ShootingTargetsTypes } from '../types'

export const shootingPhaseConfig = {

  /** debug zobrazenia */
  debug: {

    /** zobraz terce */
    showTargets: false,

    /** zobraz mieritko */
    showShootingPoint: false

  },

  /** startovacia pozicia terca strely */
  targetOriginPosition: {
    [ShootingTargetsTypes.prone]: new THREE.Vector3(-78.346, 0.75, 8.79),
    [ShootingTargetsTypes.standing]: new THREE.Vector3(-78.346, 0.75, 11.25)
  },

  /** o kolko od origin pointu sa vieme max. posunut */
  movementRange: new THREE.Vector2(0.6, 0.15),

  /** o kolko sa posunie zaciatocna pozicia na zaciatku. Neprekroci limit z movementRange */
  startAimPosition: {

    vertical: {
      max: 0.1,
      min: -0.1
    },

    horizontal: {
      max: 0.1,
      min: -0.1
    }

  },

  /** citlivost */
  sensitivity: {

    /** po kolkych % obrazovky presunieme target o 100% */
    screenToMaxTarget: new THREE.Vector2(0.25, 0.25),

    /** citlivost joystiku */
    joystickStep: new THREE.Vector2(0.01, 0.01)

  },

  /** pozicia z ktorej strielame */
  cameraWrapperPosition: {
    [ShootingTargetsTypes.prone]: new THREE.Vector3(-74, 0.75, 8.79),
    [ShootingTargetsTypes.standing]: new THREE.Vector3(-74, 0.75, 11.25)
  },

  /** polomer mieritka */
  scopeRadius: 0.01,

  /** polomer terca */
  targetRadius: {
    [ShootingTargetsTypes.prone]: 0.0225,
    [ShootingTargetsTypes.standing]: 0.055
  },

  /** pozicie targetov a id podla ktoreho hladame terc ktory budeme tocit pri trafeni */
  targetPositions: {
    [ShootingTargetsTypes.prone]: [
      { id: 1,
        position: new THREE.Vector3(-78.346, 0.641, 9.27) },
      { id: 2,
        position: new THREE.Vector3(-78.346, 0.641, 9.031) },
      { id: 3,
        position: new THREE.Vector3(-78.346, 0.641, 8.791) },
      { id: 4,
        position: new THREE.Vector3(-78.346, 0.641, 8.551) },
      { id: 5,
        position: new THREE.Vector3(-78.346, 0.641, 8.311) }
    ],
    [ShootingTargetsTypes.standing]: [
      { id: 1,
        position: new THREE.Vector3(-78.346, 0.641, 11.73) },
      { id: 2,
        position: new THREE.Vector3(-78.346, 0.641, 11.491) },
      { id: 3,
        position: new THREE.Vector3(-78.346, 0.641, 11.251) },
      { id: 4,
        position: new THREE.Vector3(-78.346, 0.641, 11.011) },
      { id: 5,
        position: new THREE.Vector3(-78.346, 0.641, 10.771) }
    ]
  },

  /** zobraz diery od striel */
  showBulletHoles: true,

  /** kolko vystrelov mame */
  attemptCount: 5,

  /** kolko vystrelov mame v tutorialy */
  attemptCountTraining: 10,

  /** aky delay mame po strelbe vo framoch */
  delayFrames: 30,

  /** Vychylovanie */
  autoMovement: {

    /** ci je zapnute */
    isEnabled: true,

    /** minimalna rychlost vychylovania na osi x a y */
    minStep: 0.002,
    minStepMobile: 0.0005,

    /** maximalna rychlost vychylovania na osi x a y */
    maxStep: 0.004,
    maxStepMobile: 0.0025,

    /** koef vychylovania pre strelbu v stoji / lahu */
    coefShootingType: {
      [ShootingTargetsTypes.prone]: 1,
      [ShootingTargetsTypes.standing]: 3
    },

    /** ako casto sa bude menit smer */
    directionFrames: 6,

    /** sanca, ze sa nebude menit smer v % */
    chanceSameDirection: 0.3

  },

  /** kolko framov ma trvat preklopenie terca po trafeni */
  targetFlipFrames: 5,

  /** vzdialenost pusky od kamery (pozor: pivot point nie je uplne presny) */
  rifleTranslationFromCamera: 0.1,

  /** Nastavenia kamery */
  camera: {

    near: 0.03,
    far: 1000,
    fov: 20

  },

  /**
   * Animacie:
   */

  /** skok pusky po vystrele */
  rifleJump: {

    /** kolko framov pred skoncenim delayu ma byt puska v pripravenom stave */
    beforeDelayEnd: 0,

    /** ako velmi sa puska zrotuje po vystrele */
    rotationX: -Math.PI / 64,

    /** ako velmi sa puska posunie po vystrele */
    positionZ: 0.05,

    /** kolko framov ma trvat skok pusky hore */
    framesRotationXup: 3,

    /** kolko framov ma trvat navrat pusky zo skoku */
    framesRotationXdown: 11,

    /** kolko framov ma trvat pohyb dozadu po strelbe */
    framesMovementZback: 3,

    /** kolko framov ma trvat pohyb vratenia sa pusky po strelbe */
    framesMovementZforward: 11

  },

  /** animacia nabijania */
  rifleLoad: {

    /** na akom mieste zacne nabojnica po vystrele */
    bulletStartPosition: new THREE.Vector3(-0.24, -0.025, -0.035),

    /** na akom mieste skonci nabojnica po vystrele */
    bulletTargetPosition: new THREE.Vector3(-0.24, 0.025, -0.2),

    /** ako dlho pohyb nabojnice bude trvat */
    framesBulletMovemet: 12,

    /** o kolko sa zrotuje puska pri nabijani */
    rifleRotationX: Math.PI / 24,

    /** ako dlho bude trvat rotacia k bodu vyssie */
    framesRotateToTarget: 6,

    /** ako dlho bude puska bez pohybu */
    framesWaiting: 4,

    /** ako dlho bude trvat dokail sa puska vrati naspat */
    framesRotatingBack: 6

  }

}
