import {
  fpsManager,
  THREE
} from '@powerplay/core-minigames'

export const shootingOutroConfig = {

  // ci chceme zobrazit krivku pre debug
  debugShowCurve: false,

  /*
   * cesta zo strelnice sa sklada z bezierovej krivky a rovnej krivky
   * stredny bod krivky - pricitame na startovaciu poziciu
   */
  bezierMiddle: new THREE.Vector3(4, 0, -2),

  // konecny bod - pricitame na bezierMiddle
  bezierEnd: new THREE.Vector3(1, 0, -8),

  // konecny bod rovnej krivky - pricitame na bezierEnd
  lineEnd: new THREE.Vector3(0, 0, 0),

  // ako dlho trva animacia
  moveDuration: 2 * fpsManager.fpsLimit // seconds -> frames

}
