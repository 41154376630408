import { THREE } from '@powerplay/core-minigames'
import { unitCyclesConfig } from '../../config/unitCyclesConfig'
import { player } from '.'
import { inputsManager } from '../../InputsManager'
import {
  directionsState,
  movementState
} from '@/stores'

/**
 * Trieda pre spravu jednotkovej kruznice pre hracove inputy
 */
export class PlayerInputsUnitCycleManager {

  /** Aktualna hodnota na jednotkovej kruznici */
  private actualValue = 0

  /**
   * Posunutie aktualnej hodnoty po jednotkovej kruznici proti smeru hodinovych ruciciek
   */
  public toLeftSide(positionX: number): void {

    if (!player.activeUpdatingMovementAnimations) return

    const maxAngleFast = unitCyclesConfig.playerInputs.maxAngleFast

    let maxAngle: number
    if (positionX) {

      maxAngle = unitCyclesConfig.playerInputs.maxTurnAngle * Math.abs(positionX)

    } else {

      maxAngle = unitCyclesConfig.playerInputs.maxTurnAngle

    }

    if (player.isCrouching) maxAngle = unitCyclesConfig.playerInputs.maxTuckAngle

    // Ak je to mozne, tak urychlime proces posuvania
    if (this.actualValue > maxAngleFast) this.actualValue = maxAngleFast

    this.actualValue -= unitCyclesConfig.playerInputs.coefAdd

    // menej ako min uz nedavame
    if (this.actualValue < -maxAngle) this.actualValue = -maxAngle

  }

  /**
   * Posunutie aktualnej hodnoty po jednotkovej kruznici v smere hodinovych ruciciek
   */
  public toRightSide(positionX: number): void {

    if (!player.activeUpdatingMovementAnimations) return

    const maxAngleFast = unitCyclesConfig.playerInputs.maxAngleFast
    let maxAngle: number
    if (positionX) {

      maxAngle = unitCyclesConfig.playerInputs.maxTurnAngle * Math.abs(positionX)

    } else {

      maxAngle = unitCyclesConfig.playerInputs.maxTurnAngle

    }

    if (player.isCrouching) maxAngle = unitCyclesConfig.playerInputs.maxTuckAngle

    // Ak je to mozne, tak urychlime proces posuvania
    if (this.actualValue < -maxAngleFast) this.actualValue = -maxAngleFast

    this.actualValue += unitCyclesConfig.playerInputs.coefAdd

    // viac ako po max uz nedavame
    if (this.actualValue > maxAngle) this.actualValue = maxAngle

  }

  /**
   * Posunutie aktualnej hodnoty po jednotkovej kruznici v pripade ak sa netlaci tlacidlo
   */
  public pivotToCenter(): void {

    const pivotCoef = unitCyclesConfig.playerInputs.coefReturnToCenter

    if (this.actualValue > 0) {

      this.actualValue -= pivotCoef

      if (this.actualValue < 0) this.actualValue = 0

    } else {

      this.actualValue += pivotCoef

      if (this.actualValue > 0) this.actualValue = 0

    }

  }

  /**
   * Aktualizovanie jednotkovej kruznice
   */
  public update(): void {

    if (inputsManager.moveDirectionLeft || movementState().positionX < 0) {

      this.toLeftSide(movementState().positionX)

    } else if (
      inputsManager.moveDirectionRight || movementState().positionX > 0
    ) {

      this.toRightSide(movementState().positionX)

    } else {

      this.pivotToCenter()

    }

    // UI update
    directionsState().movement = THREE.MathUtils.radToDeg(this.actualValue)

  }

  /**
   * Vypocitanie percent aktualnej hodnoty na jednotkovej kruznici
   * @returns Hodnota v %
   */
  public calculatePercentage(): number {

    return this.actualValue / unitCyclesConfig.playerInputs.maxTurnAngle

  }

  /**
   * resetneme hodnotu
   */
  public reset(): void {

    this.actualValue = 0

  }

}
