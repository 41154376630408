/**
 * Konfig pre tep srdca
 */
export const heartRateConfig = {

  /** Zmena tepu sprint */
  sprintPhaseAdd: 5,

  /** Zmena tepu downhill */
  downhillPhaseRemove: -3,

  /** Zmena tepu strelba */
  shootingPhaseRemove: -1,

  /** Zmena tepu beh hore */
  runningPhaseAdd: 2,

  /** Zmena tepu beh dole */
  runningPhaseRemove: -2,

  /** Minimalna hodnota tepu */
  minRate: 120,

  /** Maximalna hodnota tepu */
  maxRate: 200,

  /** Hodnota pre vypocet speedBarHeartRate hodnoty */
  speedBarCoef: 190

}
