<template>
  <section
    v-if="heartRateState.showRate|| uiState.showTrainingLayout"
    class="heart-rate-position"
    :class="{'shoot': actionButtonState.isShooting}"
  >
    <heart-monitor-box
      :heart-rate="String(heartRateState.heartRate)"
      :glow="glow"
    />
  </section>
</template>

<script lang="ts">
import { HeartMonitorBox } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import {
  actionButtonState,
  uiState,
  heartRateState
} from '@/stores'

export default defineComponent({
  name: 'HearthRateComponent',
  components: {
    HeartMonitorBox
  },
  props: {
    glow: {
      type: Boolean,
      default: false
    }
  },
  data() {

    return {
      actionButtonState: actionButtonState(),
      uiState: uiState(),
      heartRateState: heartRateState(),
    }

  },
})
</script>
