import type { TranslatesReplacements } from '@powerplay/core-minigames'

/**
 * Vymeny textov pre preklady - prekonkretnu disciplinu
 */
export const translatesReplacements: TranslatesReplacements[] = [

  {
    text: 'instructionsDisciplineTraining2Cell1Text1',
    replace: [
      {
        original: '[number]',
        new: '10'
      }
    ]
  },
  {
    text: 'instructionsDisciplineTraining2Cell2Text1',
    replace: [
      {
        original: '[number]',
        new: '15s'
      }
    ]
  },
  {
    text: 'instructionsDiscipline2Cell7Text2',
    replace: [
      {
        original: '[number]',
        new: '10'
      }
    ]
  },

]
