<template>
  <div>
    <traffic-light-box
      v-if="!startMessageState.showMessage && directionsState.wrongWay"
      class="traffic-light-box"
      :color="2"
      :text="$t('wrongWay')"
    />
    <traffic-light-box
      v-if="startMessageState.showMessage"
      class="traffic-light-box"
      :color="startMessageState.messageColor"
      :text="$t(startMessageState.messageText)"
    />
  </div>
</template>

<script lang="ts">
import { TrafficLightBox } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import {
  directionsState,
  startMessageState
} from '@/stores'

export default defineComponent({
  name: 'TrafficComponent',
  components: {
    TrafficLightBox
  },
  data() {

    return {
      directionsState: directionsState(),
      startMessageState: startMessageState(),
    }

  }
})
</script>

<style lang="less" scoped>
.traffic-light-box{
    position: absolute;
    left: 50%;
    top: 5%;
}
</style>
