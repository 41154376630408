/**
 * Konfig pre finalnu fazu
 */
export const finishPhaseConfig = {

  /** Nelinearna faza znizovania rychlosti */
  nonLinearDecreasingSpeed: {

    /** Po kolkatich freamoch skonci tato faza */
    frames: 90,

    /** raz za x frameov sa zmeni rychlost */
    everyXFrames: 3,

    /** Rychlost sa prenasobi tymto koeficientom */
    coef: 0.95
  },

  /** linearna faza znizovania rychlosti */
  linearDecreasingSpeed: {

    /** Po kolkatich frameoch skonci tato faza */
    frames: 60

  },

  /** Pocet frameov, ktore bude lyziar stat po linearnej faze, dokym sa neukonci minihra */
  stayAtEndFrames: 30,

  /** Po kolkatich frameoch po prejdeni cielom sa prehra animacia end */
  startAnimationEndAfterFrames: 10

}
