<template>
  <div
    :class="isMobile() ? 'positioner' : 'nomobile-positioner'"
    :style="addStyle"
  >
    <speed-control-bar
      :fill-size="gamePhaseState.speedPower"
      :max-fill="gamePhaseState.maxSpeed"
      :reversed-mode="true"
      :is-scaled="isScaled"
    />
  </div>
</template>

<script lang="ts">
import {
  WindowAspect,
  SpeedControlBar
} from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { gamePhaseState } from '@/stores'

export default defineComponent({
  name: 'SpeedBar',
  components: {
    SpeedControlBar
  },
  mixins: [WindowAspect],
  props: {
    isLeft: {
      type: Boolean,
      default: false
    },
    isScaled: {
      type: Boolean,
      default: true
    }
  },
  data() {

    return {
      gamePhaseState: gamePhaseState(),
    }

  },
  computed: {
    addStyle() {

      if (this.isLeft) return 'justify-content: flex-end;'
      return ''

    }
  }
})
</script>

<style scoped lang="less">
.positioner {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 85%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    pointer-events: none;
}

.nomobile-positioner {
    pointer-events: none;
    position: absolute;
    left: 40%;
    bottom: 5%;
    .power-start-bar {
        transform-origin: center bottom;
    }
}
</style>
