import {
  THREE,
  MobileDetector,
  game
} from '@powerplay/core-minigames'
import { heartRateConfig } from '../config/heartRateConfig'
import { shootingPhaseConfig } from '../config/shootingPhaseConfig'
import { player } from '../entities/player'
import { shootingTargetsManager } from '../shootingTargetsManager'
import { Direction } from '../types'
import { movementState } from '@/stores'

/**
 * manazer vychylovania pri strelbe
 */
export class ShootingDirectionManager {

  /** kolko smerov mame */
  private CHANGE_DIRECTION_COUNT = 8

  /** nahodne vygenerovany smer */
  private actualDirection = this.getRandomDirection()

  /** posledna pozicia mysy */
  private lastMousePosition = new THREE.Vector2()

  /** aktualna pozicia mysy */
  public actualMousePosition = new THREE.Vector2()

  /** o kolko posuvame terc podla mysy */
  private mouseStep = new THREE.Vector2()

  /** pocitadlo framov pre zmenu smeru pri vychylovani */
  private changeFramesCount = 0

  /** miesto kam mierime */
  public targetPoint = new THREE.Mesh()

  /** velkost kroku v kazdom smere */
  private directionStep: Record<number, THREE.Vector2> = {}

  /** Limity na hybanie sa */
  private limitsToMove = {
    minZ: 0,
    maxZ: 0,
    minY: 0,
    maxY: 0
  }

  /** min step vychylovania */
  private minStepAuto = 0

  /** max step vychylovania */
  private maxStepAuto = 0

  /** ci sme urobili init shift */
  private isInitShiftDone = false

  /**
   * Inicializacia
   */
  public init(): void {

    this.createDirectionSteps()
    this.setLimitsToMove()

  }

  /**
   * Nastavenie limitov na pohyb
   */
  public setLimitsToMove(): void {

    const shootingType = shootingTargetsManager.getActualShootingType()
    const middlePosition = shootingPhaseConfig.targetPositions[shootingType][2].position

    this.limitsToMove.minZ = middlePosition.z - shootingPhaseConfig.movementRange.x
    this.limitsToMove.maxZ = middlePosition.z + shootingPhaseConfig.movementRange.x
    this.limitsToMove.minY = middlePosition.y - shootingPhaseConfig.movementRange.y
    this.limitsToMove.maxY = middlePosition.y + shootingPhaseConfig.movementRange.y

  }

  /**
   * ziskame nahodny smer
   * @returns nahodne cislo podla poctu smerov
   */
  private getRandomDirection(): number {

    return Math.floor(Math.random() * this.CHANGE_DIRECTION_COUNT)

  }

  /**
   * Aktualizovanie
   */
  public update(): void {

    this.updateTargetPosition()
    // ak nemame zapnute vychylovanie tak koncime
    if (!shootingPhaseConfig.autoMovement.isEnabled) return
    this.changeActualChangeDirection()
    this.changeTargetPosition()

  }

  /**
   * zmenime smer vychylovania
   */
  private changeActualChangeDirection(): void {

    this.changeFramesCount++
    const config = shootingPhaseConfig.autoMovement
    if (this.changeFramesCount % config.directionFrames) return

    const chance = Math.random()

    if (chance <= config.chanceSameDirection) return // nemenime smer

    // toto zahrna aj momentalny smer, mozno bude treba upravit
    this.actualDirection = this.getRandomDirection()

  }

  /**
   * zmenime poziciu target pointu podla pravidiel vychylovania
   */
  public changeTargetPosition(): void {

    const config = shootingPhaseConfig.autoMovement
    const heartRate = player.heartRateManager.getHeartRate()

    // spravime zaklad normalizovany na 1
    const change = this.directionStep[this.actualDirection].clone()

    // zistime percentualnu hodnotu medzi min a max tepom
    const { minRate, maxRate } = heartRateConfig
    const heartRatePercent = (heartRate - minRate) / (maxRate - minRate)

    // base hodnota podla tepu
    const baseChangeValue = this.minStepAuto +
            (heartRatePercent * (this.minStepAuto - this.minStepAuto))

    // podla toho ci ide o lezku alebo stojku spravime koef
    const coefShootingType = config.coefShootingType[
      shootingTargetsManager.getActualShootingType()
    ]

    // konecne prenasobenie
    change.x *= (baseChangeValue * coefShootingType)
    change.y *= (baseChangeValue * coefShootingType)

    this.moveTargetPoint(change.x, change.y)

  }

  /**
   * vytvorime objekt v ktorom si zaznacime zakladne velkosti vychylovania
   */
  private createDirectionSteps(): void {

    this.directionStep[Direction.N] = new THREE.Vector2(0, 1)
    this.directionStep[Direction.NE] = new THREE.Vector2(0.5, 0.5)
    this.directionStep[Direction.E] = new THREE.Vector2(1, 0)
    this.directionStep[Direction.SE] = new THREE.Vector2(0.5, -0.5)
    this.directionStep[Direction.S] = new THREE.Vector2(0, -1)
    this.directionStep[Direction.SW] = new THREE.Vector2(-0.5, -0.5)
    this.directionStep[Direction.W] = new THREE.Vector2(-1, 0)
    this.directionStep[Direction.NW] = new THREE.Vector2(-0.5, 0.5)

    const { minStep, maxStep, minStepMobile, maxStepMobile } = shootingPhaseConfig.autoMovement

    if (MobileDetector.isMobile()) {

      this.minStepAuto = minStepMobile
      this.maxStepAuto = maxStepMobile

    } else {

      this.minStepAuto = minStep
      this.maxStepAuto = maxStep

    }

  }

  /**
   * zmenime poziciu target pointu podla hracovho inputu
   */
  private updateTargetPosition(): void {

    if (MobileDetector.isMobile()) {

      // MOBILNA CAST
      const joystickStep = shootingPhaseConfig.sensitivity.joystickStep
      const x = movementState().positionX * joystickStep.x
      const y = movementState().positionY * joystickStep.y

      this.moveTargetPoint(x, (-1 * y))
      return

    }

    // WEBOVA CAST
    if (!this.isInitShiftDone) {

      this.lastMousePosition = this.actualMousePosition.clone()
      this.makeInitTargetShift()

    }

    const diff = new THREE.Vector2(
      this.lastMousePosition.x - this.actualMousePosition.x,
      this.lastMousePosition.y - this.actualMousePosition.y
    )

    this.moveTargetPoint(-1 * diff.x * this.mouseStep.x, diff.y * this.mouseStep.y)
    this.lastMousePosition = this.actualMousePosition.clone()

  }

  /**
   * moves target point
   *
   * @param x - how much on Z axis should we move
   * @param y - how much on Y axis shoud we move
   */
  private moveTargetPoint(x: number, y: number): void {

    let newZ = this.targetPoint.position.z - x
    let newY = this.targetPoint.position.y + y

    // kontrola max. polohy
    const { minZ, maxZ, minY, maxY } = this.limitsToMove

    if (newZ < minZ) newZ = minZ
    if (newZ > maxZ) newZ = maxZ
    if (newY < minY) newY = minY
    if (newY > maxY) newY = maxY

    this.targetPoint.position.z = newZ
    this.targetPoint.position.y = newY

  }

  /**
   * posunieme pociatocnu poziciu targetu pri myske
   */
  private makeInitTargetShift(): void {

    if (this.isInitShiftDone) return
    this.isInitShiftDone = true

    const { maxY, minY, maxZ, minZ } = this.limitsToMove

    // najprv pomerovo posunieme podla pozicie mysky
    const ratioZ = 1 - this.lastMousePosition.x / window.innerWidth
    const ratioY = 1 - this.lastMousePosition.y / window.innerHeight

    this.targetPoint.position.z = minZ + (maxZ - minZ) * ratioZ
    this.targetPoint.position.y = minY + (maxY - minY) * ratioY

    // vypocitanie posunu o random hodnotu
    const { horizontal, vertical } = shootingPhaseConfig.startAimPosition
    const shiftValue = new THREE.Vector2()
    shiftValue.x = Math.random() * (horizontal.max - horizontal.min) + horizontal.min
    shiftValue.y = Math.random() * (vertical.max - vertical.min) + vertical.min

    this.targetPoint.position.z += shiftValue.x
    this.targetPoint.position.y += shiftValue.y

    // kontrola ci sme nevysli z extremov
    if (this.targetPoint.position.z < minZ) this.targetPoint.position.z = minZ
    if (this.targetPoint.position.z > maxZ) this.targetPoint.position.z = maxZ
    if (this.targetPoint.position.y < minY) this.targetPoint.position.y = minY
    if (this.targetPoint.position.y > maxY) this.targetPoint.position.y = maxY

  }

  /**
   * registrujeme mouse event aby sme mohli zbierat poziciu
   */
  public setMouseStep(): void {

    if (MobileDetector.isMobile()) return

    const gameSize = new THREE.Vector2(
      document.getElementById('game-container')?.clientWidth,
      document.getElementById('game-container')?.clientHeight
    )

    this.mouseStep = new THREE.Vector2(

      (shootingPhaseConfig.movementRange.x / (gameSize.x)) /
                shootingPhaseConfig.sensitivity.screenToMaxTarget.x,

      (shootingPhaseConfig.movementRange.y / (gameSize.y)) /
                shootingPhaseConfig.sensitivity.screenToMaxTarget.y

    )

  }

  /**
   * Creates target point which we move around
   */
  public createTargetPoint(): void {

    const geometry = new THREE.SphereGeometry(shootingPhaseConfig.scopeRadius)
    const material = new THREE.MeshBasicMaterial({
      color: new THREE.Color(0xFFFF00)
    })

    this.targetPoint = new THREE.Mesh(geometry, material)
    this.targetPoint.name = 'shooting_target_point'

    if (!shootingPhaseConfig.debug.showShootingPoint) this.targetPoint.visible = false

    this.targetPoint.position
      .copy(shootingPhaseConfig.targetOriginPosition[shootingTargetsManager.getActualShootingType()])
    game.scene.add(this.targetPoint)

  }

  /**
   * odstranime target point zo sceny
   */
  public removeObjectsFromScene(): void {

    this.targetPoint.geometry.dispose()

    if (this.targetPoint.material instanceof Array) {

      this.targetPoint.material.forEach((material: THREE.Material) => material.dispose())

    } else {

      this.targetPoint.material.dispose()

    }

    game.scene.remove(this.targetPoint)

  }

  /**
   * Resetovanie property objektu
   */
  public reset(): void {

    this.isInitShiftDone = false
    this.setLimitsToMove()

  }

}

export const shootingDirectionManager = new ShootingDirectionManager()
