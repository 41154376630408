/**
 * typ pri zobrazovani hlasok hracovi
 */
export interface DisplayMessage {
  message: string,
  color: number
}

/**
 * cas zobrazovany pocas hry
 */
export interface TimeData {

  time: string,
  timeDiff: string

}

/**
 * farby malych action buttonov
 */
export enum SmallActionButtonColors {

  green = 'green',
  yellow = 'yellow',
  red = 'red'

}
