<template>
  <div
    v-if="startPhaseState.showPhase"
    class="phase-start"
  >
    <player-info-avatar
      v-if="startPhaseState.showName"
      :texts="getTexts"
      :is-training="isTraining"
      :background-id="getAvatarBackgroundId"
      :background-ext="getAvatarBackgroundExt"
      :avatar-id="getAvatarId"
      :avatar-ext="getAvatarExt"
      :sex="getSex"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  playersManager,
  timeManager,
  modes,
  trainingManager,
  minigameConfig,
  AvatarImageExtensions
} from '@powerplay/core-minigames'
import { PlayerInfoAvatar } from '@powerplay/core-minigames-ui'
import {
  startPhaseState,
  tournamentState
} from '@/stores'

export default defineComponent({
  name: 'PhaseStart',
  components: {
    PlayerInfoAvatar
  },
  data() {

    return {
      startPhaseState: startPhaseState(),
    }

  },
  computed: {
    getTexts() {

      const bestResult = playersManager.getBestResult()
      let additionlInfo = timeManager.getTimeInFormatFromSeconds(bestResult, 1)
      if (bestResult >= minigameConfig.dnfValue) {

        additionlInfo = '-'

      }
      if (this.isTraining) {

        additionlInfo = trainingManager.bestScore.toString()

      }
      if (modes.isTournament()) {

        additionlInfo = tournamentState().bestResult

      }
      return {
        name: playersManager.players[0].name,
        country: playersManager.players[0].country.toLowerCase(),
        countryString: playersManager.players[0].countryString,
        additionlInfo,
        attempt: startPhaseState().attempt
      }

    },
    isTraining() {

      return modes.isTrainingMode()

    },
    getAvatarBackgroundId() {

      const avatarBg = playersManager.getPlayer().avatarBg
      return avatarBg === undefined ? 1 : Number(avatarBg)

    },
    getAvatarBackgroundExt() {

      // zatial takto, ak by do buducna trebalo, vieme doplnit funkcionalitu
      return AvatarImageExtensions.png

    },
    getAvatarId() {

      const avatar = playersManager.getPlayer().avatar
      return avatar === undefined ? 1 : Number(avatar)

    },
    getAvatarExt() {

      const playerInfo = playersManager.getPlayer()
      let avatarExt = playerInfo.avatarExt
      if (avatarExt === undefined) {

        avatarExt = playerInfo.avatar === 104 ?
          AvatarImageExtensions.gif :
          AvatarImageExtensions.png

      }
      return avatarExt

    },
    getSex() {

      return playersManager.getPlayer().sex

    }
  }
})
</script>

<style lang="less" scoped>
.phase-start {
  position: absolute;
  bottom: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
}
</style>
