import type { MaterialDataObject } from '@powerplay/core-minigames'
import {
  MaterialsNames,
  TexturesNames
} from '../types'

/**
 * Konfig pre materialy
 */
export const materialsConfig: MaterialDataObject = {
  [MaterialsNames.skier]: {
    meshesArray: [
      'body', 'pole_L', 'pole_R', 'riffle_1', 'ski_L', 'ski_R', 'strap', 'w_body', 'w_pole_L',
      'w_pole_R', 'w_riffle', 'w_ski_L', 'w_ski_R', 'w_strap'
    ],
    textureName: TexturesNames.skierClothes,
    playerIndex: 0
  },
  [MaterialsNames.hill]: {
    textureName: TexturesNames.hill,
    lightmap: TexturesNames.lightmapHill,
    vertexColors: true,
    isDefault: true
  },
  [MaterialsNames.transparent]: {
    alpha: 0.5,
    textureName: TexturesNames.transparent,
    meshesArray: [
      'Trees', 'AudienceGroup_00', 'AudienceGroup_01', 'AudienceGroup_02', 'AudienceGroup_03',
      'AudienceGroup_04', 'AudienceGroup_05', 'AudienceGroup_06', 'AudienceGroup_07'
    ],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.ads]: {
    textureName: TexturesNames.ads,
    meshesArray: [
      'Ads', 'Start_Gate', 'Finish_Gate', 'Barriers'
    ],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.flags]: {
    textureName: TexturesNames.flags,
    meshesArray: ['Flags']
    // lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.track]: {
    textureName: TexturesNames.track,
    meshesArray: ['Terrain', 'Terrain001', 'Track'],
    lightmap: TexturesNames.lightmapHill,
    vertexColors: true
  },
  [MaterialsNames.staticPeople]: {
    textureName: TexturesNames.staticPeople,
    meshesArray: ['StaticPeople'],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.rifle]: {
    meshesArray: ['BiathlonistRifle', 'Projectile'],
    textureName: TexturesNames.rifle,
  },
  [MaterialsNames.rocks]: {
    meshesArray: ['Rocks_01', 'Rocks_02', 'Rocks_03', 'Rocks_04', 'Rocks_05', 'Rocks_06'],
    textureName: TexturesNames.rocks,
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.impact]: {
    meshesArray: ['ImpactMark'],
    transparent: true,
    textureName: TexturesNames.impact
  },
  [MaterialsNames.startAds]: {
    meshesArray: ['Start_Ads'],
    textureName: TexturesNames.startAds,
    transparent: true,
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.targetCoverShadow]: {
    meshesArray: [
      'Target_Cover_Prone_1Plane', 'Target_Cover_Prone_2Plane', 'Target_Cover_Prone_3Plane',
      'Target_Cover_Prone_4Plane', 'Target_Cover_Prone_5Plane',
      'Target_Cover_Standing_1Plane', 'Target_Cover_Standing_2Plane',
      'Target_Cover_Standing_3Plane', 'Target_Cover_Standing_4Plane',
      'Target_Cover_Standing_5Plane',
    ],
    textureName: TexturesNames.targetCoverShadow,
    transparent: true
  },
  [MaterialsNames.targets]: {
    meshesArray: [
      'Target_Prone',
      'Target_Standing'
    ],
    textureName: TexturesNames.hill,
    vertexColors: true
  },
  [MaterialsNames.targetCover]: {
    meshesArray: [
      'Target_Cover_Prone_1',
      'Target_Cover_Prone_2',
      'Target_Cover_Prone_3',
      'Target_Cover_Prone_4',
      'Target_Cover_Prone_5',
      'Target_Cover_Standing_1',
      'Target_Cover_Standing_2',
      'Target_Cover_Standing_3',
      'Target_Cover_Standing_4',
      'Target_Cover_Standing_5'
    ],
    vertexColors: true
  }
}
