import { velocityConfig } from '@/app/config'
import { player } from '.'

/**
 * Uphill stav manager
 */
export class UphillManager {

  /** Realne citanie frameow ked stupa lyziar */
  private framesWhenPlayerIsHigher = 0

  /** Samotne povolenie ci sa smie spustat wonhill */
  private isUphillAllowed = false

  /** Predosla pozicia na osi Y potrebna na vykonanie rozhodnutia o aktualnej pozicii */
  private previousPositionY = 0

  /**
   * Public metoda na zistenie ci je uphill
   * @returns Ci je uphill povoleny
   */
  public getUphillAllowed(): boolean {

    return this.isUphillAllowed

  }

  /**
   * Zistenie ci sa ma udiat akcia zmeny povolenia uphillu
   */
  private checkIfActionMustHappen(): void {

    this.isUphillAllowed = player.getVelocityGradient() >= velocityConfig.uphillGradientLimit

  }

  /**
   * Zistime ci je hrac nizsie ako bol v predoslom frame
   */
  private checkIfPlayerIsHigher(): void {

    if (this.previousPositionY < player.playerObject.position.y) {

      this.framesWhenPlayerIsHigher++

    } else {

      this.framesWhenPlayerIsHigher = 0

    }

  }

  /**
   * Prehodime aktualnu poziciu ako minulu poziciu.
   */
  private reassignPreviousPosition(): void {

    this.previousPositionY = player.playerObject.position.y

  }

  /**
   * Postupnost logiky rozhodnutia. NEMENIT PORADIE METOD
   */
  private allowUphillLogic(): void {

    this.checkIfPlayerIsHigher()
    this.reassignPreviousPosition()
    this.checkIfActionMustHappen()

  }

  /**
   * Update metoda ktoru mame pouzit externe
   */
  public update(): void {

    this.allowUphillLogic()

  }

}
