/** Konfig pre nastavenie rychlosti hraca */
export const velocityConfig = {

  /** Speed bar veci */
  speedBar: {

    /** minimalna hodnota speed baru */
    minValue: 50,

    /** maximalna hodnota speed baru */
    maxValue: 100,

    /** hodnota pri zvysovani hodnoty speed baru */
    stepAdd: 1,

    /** hodnota pri znizovani hodnoty speed baru */
    stepRemove: 1

  },

  /**
   * Konstanta pre max rychlost
   */
  speedConstForMaxSpeed: 8,

  /**
   * Koeficient pre sprint
   */
  sprintCoef: 2.5,

  /**
   * Koeficient pre sprint
   */
  sprintGradientCoef: 0.08,

  /**
   * Forward force ktorym ide lyziar
   */
  forwardForceConst: 0.3,

  /**
   * Forward force ktorym ide lyziar pri sprinte
   */
  forwardForceSprint: 0.3,

  /**
   * Forward force pri zjazde
   */
  forwardForceCrouch: 0,

  /**
   * Forward force pre skating na starte
   */
  forwardForceSkatingStart: 0.3,

  /**
   * Spatna sila, ked ma lyziar rychlost vacsiu ako je max speed
   */
  backwardForce: 0.1,

  /**
   * Koeficient na prenasobenie gradientu
   */
  gradientCoef: 0.1,

  /**
   * Zmena gradientu kazdy x-ty frame
   */
  changeGradientXFrames: 10,

  /**
   * Minimalny gradient, ktory musi byt, aby to bolo detekovane, ze ideme hore kopcom
   */
  uphillGradientLimit: 3,

  /**
   * Minimalny gradient, ktory musi byt, aby to bolo detekovane, ze ideme dole kopcom
   */
  downhillGradientLimit: -3,

  /**
   * Najnizsia mozna rychlost
   */
  minMaxSpeedCoef: 2,

  /** Minimalne sprint bonus */
  minSprintBonus: 0.5,

  /** % hodnota gradientu trate, pod ktorou nie je dovoleny sprint */
  gradientForDisabledSprint: -9,

  /** coef spomalovania */
  backwardForceUphillCoef: 0.009

}
