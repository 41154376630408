import {
  audioManager,
  THREE
} from '@powerplay/core-minigames'
import {
  audioGameConfig,
  triggerEntityConfig
} from '../config'
import { player } from '../entities/player'
import { TriggerNames } from '../entities/trigger/Trigger'
import { disciplinePhasesManager } from '../phases/DisciplinePhasesManager'
import {
  type AfterShootAudio,
  AudienceChangeVolumePlaceTypes,
  AudienceChangeVolumeTypes,
  AudienceVolumeSectorTypes,
  AudioNames,
  DisciplinePhases
} from '../types'

/**
 * metody ktore pomahaju pri spustani zvukov
 */
export class AudioHelper {

  /** pocitadlo framov po vystrele */
  private audioAfterShoot: AfterShootAudio = {
    wasShot: false,
    count: 0,
    audioName: '',
    playedHit: false
  }

  /** docasny hack pre hype volume */
  public hypeVolume = { vol: 0 }

  /** Uz pouzite triggery pre zmenu hlasitosti */
  private usedAudienceChangeVolumeTriggers = {
    [AudienceChangeVolumeTypes.decrease]: {
      [AudienceChangeVolumePlaceTypes.start]: 0,
      [AudienceChangeVolumePlaceTypes.stop]: 0
    },
    [AudienceChangeVolumeTypes.increase]: {
      [AudienceChangeVolumePlaceTypes.start]: 0,
      [AudienceChangeVolumePlaceTypes.stop]: 0
    }
  }

  /** Aktualny sektor pre hlasitost divakov */
  private actualAudienceVolumeSector = AudienceVolumeSectorTypes.full

  /** Vzidalenost medzi dvomi triggermi, aby sme vedeli, ako dlhy je aktualny sektor */
  private audioVolumeActualSectorDistance = 0

  /** Trigger pre hlasitost divakov pri postupnom zvysovani/znizovani hlasitosti - do */
  private audienceVolumeTriggerPositionTo = new THREE.Vector3()

  /**
   * pustime movemement audio
   * @param name - movement audio name
   */
  public playMovementAudio(name: AudioNames): void {

    if (disciplinePhasesManager.actualPhase === DisciplinePhases.shooting) return

    audioManager.play(name)
    this.stopAllMovementAudio(name)

  }

  /**
   * zastavime vsetko audio okrem vynimky
   * @param except - ktore audio nemame zastavovat
   */
  public stopAllMovementAudio(except?: AudioNames): void {

    if (except !== AudioNames.runSlowpace) {

      audioManager.stopAudioByName(AudioNames.runSlowpace)

    }

    if (except !== AudioNames.runFastpace) {

      audioManager.stopAudioByName(AudioNames.runFastpace)

    }

    if (except !== AudioNames.skiingGlide) {

      audioManager.stopAudioByName(AudioNames.skiingGlide)

    }

    if (except !== AudioNames.skiingBreak) {

      audioManager.stopAudioByName(AudioNames.skiingBreak)

    }

  }

  /**
   * Nastavenie veci pre zmenu hlasitosti po prejdeni triggerom
   * @param type - Typ zmeny hlasitosti (increase|decrease)
   * @param place - Miesto zmeny hlasitosti (start|stop)
   */
  public setChangeAudienceVolumeAfterTrigger(
    type: AudienceChangeVolumeTypes,
    place: AudienceChangeVolumePlaceTypes
  ): void {

    if (this.usedAudienceChangeVolumeTriggers[type][place]) return

    this.usedAudienceChangeVolumeTriggers[type][place] = 1

    if (type === AudienceChangeVolumeTypes.decrease) {

      this.onTriggerChangeAudienceVolumeDecrease(place)

    }

    if (type === AudienceChangeVolumeTypes.increase) {

      this.onTriggerChangeAudienceVolumeIncrease(place)

    }

  }

  /**
   * Nastavenie veci pre zmenu hlasitosti po prejdeni triggerom decrease
   * @param place - Miesto zmeny hlasitosti (start|stop)
   */
  private onTriggerChangeAudienceVolumeDecrease(place: AudienceChangeVolumePlaceTypes): void {

    if (place === AudienceChangeVolumePlaceTypes.start) {

      this.actualAudienceVolumeSector = AudienceVolumeSectorTypes.graduallyDecrease

      const triggerPositionFrom = triggerEntityConfig.audioTriggers
        .filter(trigger => trigger.name === TriggerNames.audienceDecreaseVolumeStart)[0].position
      const triggerPositionTo = triggerEntityConfig.audioTriggers
        .filter(trigger => trigger.name === TriggerNames.audienceDecreaseVolumeStop)[0].position

      this.audioVolumeActualSectorDistance = triggerPositionFrom.distanceTo(triggerPositionTo)
      this.audienceVolumeTriggerPositionTo.copy(triggerPositionTo)

    }

    if (place === AudienceChangeVolumePlaceTypes.stop) {

      this.actualAudienceVolumeSector = AudienceVolumeSectorTypes.min
      audioManager.changeAudioVolume(AudioNames.audienceNoise, 0.2)

    }

  }

  /**
   * Nastavenie veci pre zmenu hlasitosti po prejdeni triggerom increase
   * @param place - Miesto zmeny hlasitosti (start|stop)
   */
  private onTriggerChangeAudienceVolumeIncrease(place: AudienceChangeVolumePlaceTypes): void {

    if (place === AudienceChangeVolumePlaceTypes.start) {

      this.actualAudienceVolumeSector = AudienceVolumeSectorTypes.graduallyIncrease
      const triggerPositionFrom = triggerEntityConfig.audioTriggers
        .filter(trigger => trigger.name === TriggerNames.audienceIncreaseVolumeStart)[0].position
      const triggerPositionTo = triggerEntityConfig.audioTriggers
        .filter(trigger => trigger.name === TriggerNames.audienceIncreaseVolumeStop)[0].position

      this.audioVolumeActualSectorDistance = triggerPositionFrom.distanceTo(triggerPositionTo)
      this.audienceVolumeTriggerPositionTo.copy(triggerPositionTo)

    }

    if (place === AudienceChangeVolumePlaceTypes.stop) {

      this.actualAudienceVolumeSector = AudienceVolumeSectorTypes.full
      audioManager.changeAudioVolume(AudioNames.audienceNoise, 1)

    }

  }

  /**
   * Aktualizacia hlasitosti divakov
   */
  private updateAudienceVolume(): void {

    const { audienceVolumeSectorTypesGradually } = audioGameConfig
    if (!audienceVolumeSectorTypesGradually.includes(this.actualAudienceVolumeSector)) {

      return

    }

    // spravime si distance a podla toho hlasitost
    const distance = player.getPosition().distanceTo(this.audienceVolumeTriggerPositionTo)

    let percent = distance / this.audioVolumeActualSectorDistance
    if (percent > 1) percent = 1

    // musime flipnut, lebo to ide opacne
    if (this.actualAudienceVolumeSector === AudienceVolumeSectorTypes.graduallyIncrease) {

      percent = 1 - percent

    }

    const newVolume = 0.2 + (percent * (1 - 0.2))
    audioManager.changeAudioVolume(AudioNames.audienceNoise, newVolume)

  }

  /**
   * updatujeme kazdy frame pri niektorych disciplinach
   * kde potrebujeme ratat framy pre spustanie zvukov
   */
  public update(): void {

    this.updateShootingPhase()

    this.updateAudienceVolume()
    // docasny hack
    this.updateHypeVolume()

  }

  /**
   * docasny hack pre hype volume
   */
  private updateHypeVolume(): void {

    audioManager.changeAudioVolume(AudioNames.audienceHype, this.hypeVolume.vol)
    audioManager.changeAudioVolume(AudioNames.audienceBells, this.hypeVolume.vol)

  }

  /**
   * updatujeme pri shooting faze
   */
  private updateShootingPhase(): void {

    if (disciplinePhasesManager.actualPhase !== DisciplinePhases.shooting) return

    if (this.audioAfterShoot.wasShot) this.audioAfterShoot.count++
    if (this.audioAfterShoot.count === 0) return

    const { count, playedHit, audioName } = this.audioAfterShoot

    if (!(count % audioGameConfig.afterShootDelayHit) && !playedHit) {

      if (audioName) {

        audioManager.stopAudioByName(audioName)
        audioManager.play(audioName)

      }
      this.audioAfterShoot.playedHit = true

    }

    if (!(count % audioGameConfig.afterShootDelayPull)) {

      audioManager.stopAudioByName(AudioNames.riflePullAction)
      audioManager.play(AudioNames.riflePullAction)
      this.audioAfterShoot.count = 0
      this.audioAfterShoot.wasShot = false
      this.audioAfterShoot.playedHit = false

    }

  }

  /**
   * nastavime audio na prehranie po vystrele
   * @param audioName - nazov zvuku ktory mame prehrat
   */
  public setShotSound(audioName: AudioNames): void {

    this.audioAfterShoot.wasShot = true
    this.audioAfterShoot.audioName = audioName

  }

}

export const audioHelper = new AudioHelper()
