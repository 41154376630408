import { THREE } from '@powerplay/core-minigames'
import { TriggerNames } from '../entities/trigger/Trigger'

/**
 * Konfig pre kameru pre closer stav
 */
export const triggerEntityConfig = {
  triggers: [
    {
      name: TriggerNames.triggerIn,
      position: new THREE.Vector3(-20, 0, 36)
    },
    {
      name: TriggerNames.triggerOut,
      position: new THREE.Vector3(-20, 0, -13.5)
    },
    {
      name: TriggerNames.triggerFinish,
      position: new THREE.Vector3(14, 0, -1.7)
    },
    {
      name: TriggerNames.triggerCameraCloser,
      position: new THREE.Vector3(-12, 1.5, 75),
      rotation: new THREE.Vector3(0, -Math.PI / 4, 0)
    },
    {
      name: TriggerNames.triggerCameraCloser,
      position: new THREE.Vector3(1, 0.5, 55),
      rotation: new THREE.Vector3(0, -Math.PI / 12, 0)
    },
    {
      name: TriggerNames.triggerUnderTheBridge,
      position: new THREE.Vector3(-8, 1.5, 71),
      rotation: new THREE.Vector3(0, -Math.PI / 5, 0)
    },
    {
      name: TriggerNames.triggerUnderTheBridge,
      position: new THREE.Vector3(0, 0.5, 60),
      rotation: new THREE.Vector3(0, -Math.PI / 12, 0)
    },
    {
      name: TriggerNames.triggerStartOfFirstHill,
      position: new THREE.Vector3(-12, 1.5, 75),
      rotation: new THREE.Vector3(0, -Math.PI / 4, 0)
    },
    {
      name: TriggerNames.triggerStartOfFirstDownhill,
      position: new THREE.Vector3(55, 11, 93.7),
      rotation: new THREE.Vector3(0, -Math.PI / 1.7, 0)
    },
    {
      name: TriggerNames.triggerEndOfFirstDownhill,
      position: new THREE.Vector3(5, 3, 73.5),
      rotation: new THREE.Vector3(0, -Math.PI / 1.6, 0)
    }
  ],
  splitTriggers: [
    {
      name: TriggerNames.triggerSplit,
      position: new THREE.Vector3(-55.25, 13.5, 75.8),
      rotation: new THREE.Vector3(0, -Math.PI / 1.55, 0)
    },
    {
      name: TriggerNames.triggerSplit,
      position: new THREE.Vector3(48.3, 25, 148.5),
      rotation: new THREE.Vector3(0, -Math.PI / 6, 0)
    }
  ],
  preSplitTriggers: [
    {
      name: TriggerNames.triggerPreSplit,
      position: new THREE.Vector3(-12, 1.5, 75),
      rotation: new THREE.Vector3(0, -Math.PI / 4, 0)
    },
    {
      name: TriggerNames.triggerPreSplit,
      position: new THREE.Vector3(-16, 11, 126.5),
      rotation: new THREE.Vector3(0, -Math.PI / 1.55, 0)
    },
    {
      name: TriggerNames.triggerPreSplit,
      position: new THREE.Vector3(5, 3, 73.5),
      rotation: new THREE.Vector3(0, -Math.PI / 1.6, 0)
    },
    {
      name: TriggerNames.triggerPreSplit,
      position: new THREE.Vector3(9, 0, -50.5),
      rotation: new THREE.Vector3(0, -Math.PI / 1.3, 0)
    }
  ],
  audioTriggers: [
    {
      name: TriggerNames.audienceDecreaseVolumeStart,
      position: new THREE.Vector3(-12, 1.5, 75),
      rotation: new THREE.Vector3(0, -Math.PI / 4, 0)
    },
    {
      name: TriggerNames.audienceDecreaseVolumeStop,
      position: new THREE.Vector3(-65, 14.5, 74.5),
      rotation: new THREE.Vector3(0, Math.PI * 0.6, 0)
    },
    {
      name: TriggerNames.audienceIncreaseVolumeStart,
      position: new THREE.Vector3(58.5, 21, 130),
      rotation: new THREE.Vector3(0, Math.PI * 1.82, 0)
    },
    {
      name: TriggerNames.audienceIncreaseVolumeStop,
      position: new THREE.Vector3(10, 6, 75.5),
      rotation: new THREE.Vector3(0, Math.PI * 0.35, 0)
    },
    {
      name: TriggerNames.hypeStart,
      position: new THREE.Vector3(-71.5, 14, 80),
      rotation: new THREE.Vector3(0, Math.PI * -0.15, 0)
    },
    {
      name: TriggerNames.hypeStop,
      position: new THREE.Vector3(-76, 19, 95),
      rotation: new THREE.Vector3(0, Math.PI * -0.1, 0)
    },
    {
      name: TriggerNames.hypeStart1,
      position: new THREE.Vector3(-35, 16, 123.5),
      rotation: new THREE.Vector3(0, -Math.PI * 1.5, 0)
    },
    {
      name: TriggerNames.hypeStop1,
      position: new THREE.Vector3(6.5, 16, 140),
      rotation: new THREE.Vector3(0, Math.PI * 0.25, 0)
    },
    {
      name: TriggerNames.hypeStart2,
      position: new THREE.Vector3(69, 17, 110),
      rotation: new THREE.Vector3(0, Math.PI * -0.15, 0)
    },
    {
      name: TriggerNames.hypeStop2,
      position: new THREE.Vector3(60, 17, 95),
      rotation: new THREE.Vector3(0, Math.PI * 0.4, 0)
    }
  ]
}
